@import "src/common/styles/variables";
@import "src/common/styles/colors";

.BotLabels {
  &__filters {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 30px;

    @media #{$breakpoint-sm-down} {
      flex-direction: column-reverse;
    }
  }

  &__search-input {
    max-width: 500px;
    width: 100%;

    @media #{$breakpoint-sm-down} {
      max-width: 100%;
    }
  }

  &__labels {
  }
}
