@import 'common/styles/mixins/transitions';

.FileUploader {
  &__selector {
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 20px;
  }

  .FileField {
    &__item {}

    &__message {
      margin-top: 10px;
      margin-left: 4px;
      font-size: 0.8em;
      font-weight: 400;

      &--error {
        color: red;
      }
    }
  }

  .file-item-enter {
    opacity: 0;
    transform: translateY(-15px) scale(0.9);
  }

  .file-item-enter-active {
    @include transition-medium();

    opacity: 1;
    //transform: translateY(0);
    transform: translateY(0px) scale(1);
  }

  .file-item-exit {
    opacity: 1;
  }

  .file-item-exit-active {
    @include transition-medium();

    opacity: 0;
    //transform: translateY(-15px);
    transform: translateY(-15px) scale(0.9);
  }
}
