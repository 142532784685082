@import "src/common/styles/variables";
@import "src/common/styles/colors";

.EntityBadge {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: $neutral-60;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
  font-weight: 400;
  padding: 2px 10px;
  width: 100px;
  border-radius: 6px;

  &__name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &--transaction {
    color: $purple;
  }

  &--address {
    color: $green;
  }

  &--url {
    color: $yellow;
  }
}
