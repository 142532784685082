@import "src/common/styles/colors";

.BotItem {
  &__name {
    color: $neutral-100;
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__id {
    font-size: 14px;
    font-weight: 400;
    color: $neutral-50;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
