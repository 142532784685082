@import 'src/common/styles/variables';

@mixin h1-lg {
  font-weight: 700;
  font-size: 72px;
  line-height: 100%;
  font-style: normal;
}

@mixin h1-md {
  font-weight: 700;
  font-size: 56px;
  line-height: 110%;
  font-style: normal;
}

@mixin h1-sm {
  font-weight: 700;
  font-size: 42px;
  line-height: 110%;
  font-style: normal;
}

@mixin h1-responsive {
  @include h1-lg;

  @media #{$breakpoint-lg-down} {
    @include h1-md;
  }

  @media #{$breakpoint-sm-down} {
    @include h1-sm;
  }
}

@mixin lead-lg {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  font-style: normal;
}

@mixin lead-md {
  font-weight: 400;
  font-size: 18px;
  line-height: 140%;
  font-style: normal;
}

@mixin lead-sm {
  font-weight: 400;
  font-size: 17px;
  line-height: 140%;
  font-style: normal;
}

@mixin lead-responsive {
  @include lead-lg;

  @media #{$breakpoint-lg-down} {
    @include lead-md;
  }

  @media #{$breakpoint-sm-down} {
    @include lead-sm;
  }
}

@mixin font-title {
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1.75rem;
}
