@import 'src/common/styles/colors';

.MyNodePoolsPage {
  color: white;

  &__content-top {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    justify-content: flex-end;
    align-items: flex-end;
  }

  &__pool-id-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
