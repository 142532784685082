@import 'src/common/styles/colors';

.WizardPage {
  &__header {
    text-align: left;
    color: white;
  }

  &__title {
    font-size: 2rem;
    text-align: left;
    display: flex;
    align-items: center;
  }

  &__description {
    font-size: 1rem;
    color: $lightgray-2;
    word-break: break-all;
    width: 100%;

    a {
      color: $blue;

      &:hover {
        color: $darkblue;
      }
    }
  }

  &__not-logged {
    text-align: center;
    margin-top: 30px;
    font-size: 1.2em;
    color: $lightgray-2;
    border: 1px solid $gray;
    padding: 100px;
  }

  &__iframe-container {
    margin-top: 30px;
    // position: relative;
    // overflow: hidden;
    // width: 100%;
    // padding-top: 56.25%;
  }
  &__iframe {
    // position: absolute;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // right: 0;
    width: 100%;
    height: 1000px;
  }

  &__beta-badge {
    display: inline-flex;
    font-size: 0.8rem;
    margin-left: 20px;
    background-color: $yellow;
    color: $black;
    padding: 4px 20px;
    border-radius: 6px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
