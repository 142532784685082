@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/line-clamp';

.BotPage {
  &__tabs {
    margin-top: 50px;
  }

  &__data {
    margin-top: 50px;
  }
}
