@use 'sass:color';
@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.ModalV2 {
  $module: &;

  @include transition-slow();

  position: absolute;
  margin: auto;
  width: calc(100% - 20px);
  border-radius: 16px;
  z-index: 1;
  background-color: $gray-4;
  outline: none;
  box-shadow: 0 0 20px 3px rgb(93 93 93 / 7%);
  border: 1px solid rgb(255 255 255 / 10%);
  overflow: hidden;

  &__overlay {
    @include transition-slow();

    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: color.scale($black, $lightness: 1%, $alpha: -50%);
    backdrop-filter: blur(4px);
    z-index: $z-index-modal;
  }

  &--p {
    &-md {
      #{$module}__body {
        padding: 20px 30px;
      }

      #{$module}__header {
        padding: 15px 30px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 90vh;
  }

  &__header {
    position: sticky;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding: 10px 20px;
    width: 100%;
    background-color: color.scale($gray-4, $alpha: -80%);
    backdrop-filter: blur(20px);
    z-index: 1;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }

  &__body {
    margin-bottom: 20px;
    white-space: pre-line;
    height: 100%;
  }

  // SIZES
  // --------

  &--sm {
    max-width: 500px;
  }

  &--md {
    max-width: 800px;
  }

  &--lg {
    max-width: 960px;
  }

  &__close-button {
    margin-left: auto;
    background-color: $gray-4;
  }

  &__title {
    font-weight: 500;
    font-size: 18px;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.6);
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  // ANIMATION

  opacity: 0;
  transition: all 180ms cubic-bezier(0.76, 0.03, 0.57, 0.98);
  transform: scale(0.6);

  &__overlay {
    opacity: 0;
    transition: opacity 180ms cubic-bezier(0.76, 0.03, 0.57, 0.98);

    &.ReactModal__Overlay--after-open {
      opacity: 1;
    }

    &.ReactModal__Overlay--before-close {
      opacity: 0;
    }
  }

  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: scale(1);
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: scale(0.8);
  }
}
