@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';
@import 'src/common/styles/mixins/line-clamp';

.AlertRowV2 {
  $module: &;

  --row-height: 60px;

  font-family: $font-text;

  &__name {
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    gap: 4px;
    color: rgb(197, 176, 176);

    &-link {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    &-mute-button {
      color: rgb(197, 176, 176);
      height: 24px;
      width: 24px;
    }
  }

  &__details {
    margin-bottom: 20px;

    &-button {
      background-color: $neutral-25;
      padding: 8px 12px !important;
      width: 88px;
      height: auto;
      border: 1px solid transparent;
      transition: 0ms;
    }
  }

  &__actions {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    gap: 30px;

    &-cell {
      // fix alignment
      padding-top: 9px !important;
    }

    @media #{$breakpoint-lg-down} {
      gap: 20px;
    }
  }

  &__metadata {
    padding: 0 24px;
  }

  // nth-child is used to make it more specific and override the default style
  #{$module}__row:nth-child(1n) {
    @include transition-medium(height);

    border-bottom-color: transparent;
  }

  &__sub-cell {
    padding-top: 11px;
    align-items: flex-start;
  }

  &__expand-description-button {
    @include transition-fast();

    text-align: left;
    color: $neutral-90;
  }

  &--long-description {
    #{$module}__row {
      height: var(--row-height) !important;
    }
  }

  &:hover {
    #{$module}__details-button {
      background-color: $neutral-30;

      &:active {
        background-color: $neutral-35;
      }
    }
  }
}
