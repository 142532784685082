@import "../../styles/colors";

.MetadataContainer {
  border-radius: 6px;

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }

  &__title {
    color: $neutral-45;
    font-size: 16px;
    font-weight: 500;
  }

  &__metadata {
    position: relative;
    min-height: 120px;
    background-color: $black;
    border-radius: 6px;
    overflow-x: auto;
    overflow-y: auto;
  }
}
