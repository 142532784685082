@import "src/common/styles/colors";

.AlertFilterPanel {
  &__chips {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 10px;
  }
}

.FilterChip {
  &__label {
    font-weight: 500;
    color: $neutral-40;
  }

  &__value {
    font-weight: 400;
    color: $neutral-90;
  }
}
