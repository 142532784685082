@use './Input.mixins' as Input;

@import 'src/common/styles/colors';

.TextField {
  $module: &;

  display: block;

  &__icon {
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &__container {
    position: relative;
  }

  &__input {
    @include Input.box();
    @include Input.box-style-base();
  }

  &--light {
    #{$module}__icon {
      @include Input.icon-light();
    }

    #{$module}__input {
      @include Input.box-style-light();

      color-scheme: light;

      &::placeholder {
        @include Input.placeholder-light();
      }

      &:hover:not(:disabled) {
        @include Input.hover-light();
      }

      &:focus {
        @include Input.focus();
      }
    }

    #{$module}__clear-button {
      &:not(:disabled) {
        &:hover {
          color: $gray-2;
        }

        &:active {
          color: $black;
        }
      }
    }
  }

  &--gray {
    #{$module}__icon {
      @include Input.icon-dark();
    }

    #{$module}__input {
      @include Input.box-style-gray();

      color-scheme: dark;

      &::placeholder {
        @include Input.placeholder-gray();
      }

      &:hover:not(:disabled):not(:focus) {
        @include Input.hover-dark();
      }

      &:focus {
        @include Input.focus();
      }
    }
  }

  &--dark,
  &--dark-big {
    #{$module}__icon {
      @include Input.icon-dark();
    }

    #{$module}__input {
      @include Input.box-style-dark();

      color-scheme: dark;

      &::placeholder {
        @include Input.placeholder-dark();
      }

      &:hover:not(:disabled):not(:focus) {
        @include Input.hover-dark();
      }

      &:focus {
        @include Input.focus();
      }
    }
  }

  &--dark-big {
    .TextField__input {
      color: rgb(255, 255, 255);
      position: relative;
      font-weight: 500;
      outline: none;
      border: 1px solid transparent;
      flex: 1 1 auto;
      background-color: $darkgray;
      font-size: 24px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0px;
      appearance: textfield;
    }
  }

  &--invalid {
    #{$module}__input {
      @include Input.invalid();
    }
  }

  &--disabled {
    @include Input.disabled();
  }
}
