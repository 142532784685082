@mixin line-clamp($amount, $line-height) {
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @supports not (-webkit-line-clamp: $amount) {
    height: $line-height * $amount;
  }
}
