@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/container';

.AppHeader {
  &__wallet {
    z-index: 1;

    @media #{$breakpoint-md-down} {
      width: 100%;
    }
  }
}

.AppFixedFooter {
  display: none;

  @media #{$breakpoint-md-down} {
    @include container;

    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    padding-top: 8px !important;
    padding-bottom: 8px !important;
    background-color: rgba(18, 18, 18, 0.8);
    backdrop-filter: blur(9px);

    &__wallet {
      z-index: 1;
    }
  }
}
