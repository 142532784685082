@import "src/common/styles/variables";
@import "src/common/styles/colors";


.Source {
  display: flex;
  align-items: center;
  margin: 0;
  gap: 8px;
  font-family: $font-display;
  border-radius: 4px;
  font-weight: 400;

  &__icon {
    width: 22px;
    height: 22px;
  }
}
