@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.TabIntro {
  display: flex;
  justify-content: space-between;

  &__content {
    // main content

    text-align: left;
    line-height: 130%;
  }

  &__additional-content {
    // not implemented yet
    // use this when you need to align something on the right side
  }

  &__title {
    font-size: 2em;
    line-height: 120%;
    margin-bottom: 14px;
  }

  &__description {
    font-size: 16px;
    line-height: 150%;
    color: $neutral-50;
    margin-bottom: 40px;
    max-width: 600px;
  }
}
