@import 'src/common/styles/colors';

.NotificationModal {
  &__form-source {
    display: flex;
    position: relative;
    column-gap: 10px;
    align-items: center;
    margin-bottom: 30px;
  }

  &__endpoint {
    margin-top: 30px;
  }

  &__form-address,
  &__bot-group,
  &__kit-selector {
    display: flex;
    flex-direction: column;
    min-width: 360px;
  }

  &__filter-form {
    margin-top: 30px;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 10px;
    margin-top: 30px;
  }

  &__description {
    color: $lightgray-3;
    margin-bottom: 24px;
  }

  &__submit-section {
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  &__submit-button {
    max-width: 260px;
    width: 100%;
  }

  &__fill-wallet-btn {
    display: none;
    position: absolute;
    right: 0;
    color: $gray;
    transition: color 0.2s ease-out;
    font-size: 0.9em;

    &:hover {
      color: $blue;
    }
  }
}

@media screen and (max-width: 520px) {
  .NotificationModal__form-field {
    flex-wrap: wrap;
  }

  .NotificationModal__form-field-label,
  .NotificationModal__form-field-value {
    width: 100%;
  }
}
