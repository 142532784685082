@use 'sass:color';
@import 'src/common/styles/colors';

.ClaimRewardsModal {
  padding: 40px;
  overflow: visible;

  &__pool {
    padding-top: 28px;
    padding-bottom: 30px;

    &-label {
      font-size: 14px;
      font-weight: 50px;
      color: $neutral-45;
    }

    &__pool-entity {
      font-size: 16px;
    }
  }

  &__claim {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 40px;
    padding: 20px 40px;

    &-total {
      padding-right: 35px;

      &-label {
        font-size: 14px;
        font-weight: 500;
        color: $neutral-40;
      }

      &-value {
        font-weight: 600;
        font-size: 20px;
      }
    }
  }
}
