@use '../Mark/Mark.mixins';

@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.Checkbox {
  $module: &;

  display: inline-flex;
  font-family: $font-display;
  cursor: pointer;

  &__container {
    position: relative;
    display: flex;
    gap: 10px;
  }

  &__body {
    font-size: 16px;
    color: $neutral-70;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    visibility: hidden;

    &:focus:not(:checked) {
      + #{$module}__mark {
        @include Mark.mark-hover();
      }
    }
  }

  &:hover:not(&--disabled):not(&--checked) {
    #{$module}__mark {
      @include Mark.mark-hover();
    }
  }

  &--checked {
    color: $neutral-100;
  }

  &--disabled {
    color: $neutral-50;
    cursor: auto;
  }
}
