@import 'src/common/styles/colors';

.AgentForm {
  border-radius: 10px;

  &__description {
    margin: 20px 0;
    font-size: 14px;
  }

  &__field {
    margin-bottom: 20px;
    font-size: 14px;

    .Input {
      background-color: $gray-2;
    }
  }

  &__field-group {
    display: flex;
    margin: 20px 0;

    .AgentForm__field {
      margin: 0;
    }
  }

  &__field-version {
    width: 15%;
    min-width: 90px;
  }

  &__field-repository {
    width: 85%;
    padding-left: 20px;
  }

  &__label {
    font-size: 14px;
    margin-bottom: 6px;
  }

  &__id {
    font-size: 14px;
    background-color: $gray-2;
    padding: 10px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  &__field {
    .file-input {
      color: $lightgray-2;
      border: 1px solid $gray;
      padding: 20px 10px;
      max-width: 400px;
      border-radius: 10px;
      transition: all 0.2s ease-out;
      cursor: pointer;

      &.errored {
        border-color: red;
      }

      &-description {
        text-align: right;
        font-style: italic;
        color: $gray;
      }

      svg {
        display: inline-block;
        width: 3em;
        height: 3em;
      }
    }

    .asterisk {
      color: red;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    align-items: baseline;
    margin-top: 40px;
  }
}
