@import "src/common/styles/colors";

.BaseFilterModal {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 60px;
  }

  &__action-button {
    flex: 1;
  }

  &__description {
    font-size: 16px;
    color: $neutral-60;
    margin-bottom: 20px;
  }
}
