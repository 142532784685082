@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/line-clamp';
@import 'src/common/styles/mixins/transitions';

.BotCard {
  $module: &;

  @include transition-fast();

  position: relative;
  text-align: left;
  padding: 32px;
  border-radius: 10px;
  background-color: $darkgray;
  border: 1px solid transparent;

  &__id {
    display: flex;
    align-items: center;
    color: $neutral-70;
    margin-bottom: 16px;
  }

  &__plan {
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 9px;
  }

  &__heading {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 16px;
    // minus subscribe button; this makes text-overflow possible
    width: calc(100% - 124px);

    #{$module}__title {
      font-size: 22px;
      font-weight: 500;
      color: $neutral-90;
      max-width: 380px;
      overflow: hidden;
      word-break: break-word;
      white-space: nowrap;
      text-overflow: ellipsis;

      &:hover {
        text-decoration: underline;
      }
    }

    #{$module}__chains {
      display: flex;
      gap: 4px;
      // visual alignment
      margin-top: -4px;

      #{$module}__chain {
        display: flex;
        align-items: center;
        width: 24px;
        height: 24px;
      }
    }
  }

  &__description {
    @include line-clamp(2, 23px);

    max-width: 618px;
    font-size: 16px;
    font-weight: 400;
    line-height: 140%;
    color: $neutral-50;
    margin-bottom: 24px;
    min-height: 48px;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 40px;
  }

  &__actions {
    position: absolute;
    right: 32px;
    top: 32px;

    .Button {
      font-weight: 600;
    }
  }

  .MetaField {
    display: flex;
    align-items: center;
    gap: 6px;

    &__label {
      display: inline-flex;
      font-size: 14px;
      font-weight: 400;
      color: $lightgray-3;
      text-transform: uppercase;
    }

    &__value {
      display: inline-flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      color: $neutral-70;
    }
  }

  .BotStatus {
    $element: &;

    display: inline-flex;
    align-items: center;

    &:before {
      content: '';
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: $gray;
      margin-right: 6px;
    }

    &--enabled {
      &:before {
        background-color: $accent-green-40;
      }
    }
  }

  .CopyContainer {
    display: flex;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .copy-icon {
    margin-left: 4px;
    color: $neutral-90;
  }

  &:hover {
    background-color: lighten($darkgray, 2%);
  }

  @media #{$breakpoint-lg-down} {
    &__heading {
      align-items: flex-start;
      flex-direction: column;
      width: 100%;

      // helps to avoid problems with text-overflow
      #{$module}__title {
        @include line-clamp(1, 22px);

        white-space: unset;
      }
    }

    &__meta {
      justify-content: space-between;

      .MetaField {
        align-items: flex-start;
        flex-direction: column;
        flex-wrap: wrap;
      }
    }
  }

  @media #{$breakpoint-md-down} {
    padding: 24px 20px;

    &__id {
      margin-bottom: 10px;
    }

    &__heading {
      padding-right: 0;
    }

    &__description {
      min-height: unset;
    }

    &__meta {
      flex-direction: column;
      gap: 10px;

      .MetaField {
        align-items: center;
        flex-direction: row;
      }

      // second item
      .MetaField + .MetaField {
        order: -1;
      }
    }

    &__actions {
      position: static;
      margin-top: 30px;

      > * {
        width: 100%;
      }
    }
  }
}
