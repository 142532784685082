@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.GlobalNodePoolsPage {
  font-size: 16px;
  text-align: left;

  &__vault {
    margin-top: 60px;
    margin-bottom: 10px;
  }

  &__vault-disclaimer {
    color: $neutral-40;
    font-size: 14px;
    margin-top: 10px;
    margin-bottom: 100px;
    max-width: 900px;
  }

  &__vault-disclaimer-link {
    color: $accent-blue-30;
    font-style: italic;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 500;
    color: $neutral-90;
  }

  &__pool-id-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__search-form {
    display: flex;
    align-items: flex-end;
    gap: 24px;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 20px;
  }

  &__search-input {
    flex: 1;
    width: 100%;
    max-width: 300px;
    margin-left: auto;
  }

  &__chain-select {
    width: 100%;
    min-width: 240px;
    max-width: 300px;
  }

  @media #{$breakpoint-md-down} {
    &__search-form {
      flex-direction: column;
      margin-top: 30px;

      > * {
        flex: 1;
        max-width: 100%;
      }
    }
  }
}
