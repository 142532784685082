@import 'src/common/styles/colors';

.AlertPage {
  background-color: rgba(white, 0.1);
  background-color: black;
  color: white;
  border-radius: 10px;
  padding: 20px;
  border-color: #18365b !important;
  box-shadow: 0 0 20px rgba(white, 0.4);

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    color: $lightgray;
    font-size: 1.1em;
    margin-bottom: 30px;
    flex-wrap: wrap;
    row-gap: 10px;

    &-label {
      display: inline-flex;
      margin-right: 10px;
      row-gap: 20px;
      flex-wrap: wrap;
    }

    &-name {
      display: inline-block;
    }

    &-hash {
      display: inline-block;
      color: $gray;
      word-break: break-word;
      margin-left: 10px;
    }
  }

  &__go-back-btn {
    transform: rotate(180deg);
    margin-right: 10px;
  }

  &__scanners {
    text-align: left;
    border-top: 1px solid $gray-2;
    padding-top: 20px;

    &-title {
      font-size: 1.4em;
      color: $green;
      color: $green;
    }

    &-count {
      font-size: 0.9em;
      opacity: 0.8;
    }

    &-loader {
      text-align: center;
    }
  }

  &__scanner {
    background-color: $darkgray;
    border-radius: 10px;
    padding: 1rem;
    margin-top: 15px;

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &-item {
      margin-bottom: 10px;

      &-label {
        color: $lightgray-2;
        color: $green;
      }
      &-value {
        color: $lightgray-2;
        overflow: auto;
      }
    }

    &-date {
      text-align: right;
    }

    &-day {
      font-size: 12px;
      font-style: italic;
    }

    &-signature {
      font-family: 'Courier New', monospace;
      overflow: auto;
    }

    &-metadata {
      margin-top: 20px;
    }
  }
}

.AlertBadge {
  background-color: $blue;
  display: inline-block;
  padding: 3px 14px;
  color: white;
  border-radius: 6px;
  text-transform: capitalize;
}

.NetworkBadge {
  &--ethereum {
    color: $cyan;
  }
  &--bsc {
    color: $yellow;
  }
  &--polygon {
    color: $purple;
  }
}

.AlertDetailsTable {
  text-align: left;
  width: 100%;
  font-size: 0.9em;

  a {
    color: $blue;

    &:hover {
      opacity: 0.8;
    }
  }

  &__row {
    border-top: 1px solid rgba(white, 0.2);
  }

  &__name {
    padding: 20px 10px;
    width: 15%;
    vertical-align: baseline;

    .InfoPopover {
      margin-top: -3px;
    }
  }

  &__value {
    padding: 20px 10px;
    word-break: break-word;
  }

  &__agent {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__sources {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  &__addreses {
    background-color: #0f0f0f;
    font-family: 'Courier New', monospace;
    padding: 10px 15px;
    max-height: 500px;
    overflow-y: auto;
  }

  &__projects {
    display: flex;
    column-gap: 20px;
    row-gap: 20px;
    flex-wrap: wrap;
  }

  &__project {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: $gray-2;
    color: $lightgray;
  }

  &__address {
    padding: 10px 0;
    display: flex;

    &-actions {
      display: flex;
      margin-left: 10px;
    }

    &-label {
      display: flex;

      img {
        width: 16px;
        display: inline-block;
        margin-right: 2px;
        border-radius: 50%;
      }
    }
  }

  &__agent-id {
    display: flex;
  }

  &__alert-name {
    display: inline-block;
    margin-right: 30px;
  }

  &__alert-severity,
  &__alert-type,
  &__alert-external {
    display: inline-block;
    margin-right: 15px;
    font-size: 0.8em;
  }

  &__alert-description, &__alert-metadata {
    background-color: $darkgray;
    color: $lightgray;
    width: 100%;
    min-height: 100px;
    border: 1px solid transparent;
    border-radius: 5px;
    padding: 10px;

    &:focus {
      outline: none;
      border-color: gray;
    }
  }

  &__subscribe-cta {
    a {
      color: white;
    }
  }

  &__sources-title {
    font-size: 1.4em;
    color: $green;
    padding-left: 0.5em;
    padding-bottom: 1em;
  }
}

.external-icon {
  margin-left: 10px;
  color: white !important;
  display: inline-block;
}

@media screen and (max-width: 1440px) {
  .AlertPage__header {
    font-size: 14px;
  }
}

@media screen and (max-width: 1055px) {
  .AlertDetailsTable__agent .button-cta {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .AlertPage__header-protocol {
    width: 100%;
    text-align: center;
  }
  .AlertPage {
    font-size: 13px;
  }
}
@media screen and (max-width: 610px) {
  .AlertPage {
    overflow: hidden;
  }
  .AlertPage__scanner-row {
    display: block;
  }

  .AlertPage__scanner-item {
    .external-icon,
    .copy-icon {
      display: none;
    }
  }

  .AlertPage__scanner-date {
    text-align: left;
    position: relative;

    .AlertPage__scanner-item-label {
      display: flex;
      align-items: flex-start;

      .InfoPopover {
        margin-left: 5px;
        order: 2;

        .InfoPopover__flyout {
          right: unset;
          left: -95px;
        }
      }
    }
  }

  .AlertPage__scanners {
    .InfoPopover__flyout {
      left: -95px;
      max-width: 320px;
    }

    .AlertPage__scanner-batch {
      .InfoPopover__flyout {
        left: -95px;
        width: 300px;
        height: 60px;
        top: -65px;
        white-space: normal;
      }
    }
  }

  .AlertDetailsTable__timestamp,
  .AlertDetailsTable__block,
  .AlertDetailsTable__transaction {
    .InfoPopover__flyout {
      left: -20px;
      width: 300px;
      height: 60px;
      top: -65px;
      white-space: normal;
    }
  }
}
