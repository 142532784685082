@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';

.AlertsContainer {
  $module: &;

  position: relative;
  border-radius: 6px;
  border: 1px solid $neutral-30;
  background-color: rgba(white, 0.1);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.25rem;

    #{$module}__title {
      @include font-title();

      text-align: left;
      margin-right: 20px;
    }

    #{$module}__actions {
      display: flex;
      align-items: center;
      gap: 16px;
    }
  }

  &__filters {
    padding: 0 1.25rem 1rem;
  }
}
