@import 'src/common/styles/colors';

.ScanNodePage {
  color: white;

  &__overview {
    display: flex;
    justify-content: flex-start;
    margin-top: 30px;

    .InformationBox {
      width: 400px;
      // background-color: rgba(white, 0.1);
      padding: 10px 20px;
    }
  }

  &__loader-text {
    margin-top: 20px;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 30px;
  }

  &__scanner-info {
    text-align: left;
  }

  &__scanner-name {
    display: flex;
    column-gap: 10px;
    align-items: center;

    &-label {
      font-size: 2em;
      text-shadow: 0px 0px 6px white, 0px 0px 0px white;
    }
  }

  &__scanner-hash {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    word-break: break-all;
  }

  &__add-stake {
    display: inline-block;

    &-btn {
      border-radius: 6px;
      background-color: $gray-2;
      color: $lightgray-2;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: $darkgray;
      }
    }
  }

  &__add-icon {
    display: inline-block;
    width: 20px;
    margin-right: 10px;
  }

  &__stats {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 10px;
    margin-top: 40px;

    &-number {
      font-size: 2em;
      display: flex;
      justify-content: center;
    }

    &-operational {
      color: rgb(18, 126, 18);
    }

    &-nonoperational {
      color: $gray;
    }
  }

  &__stake-stats {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 60px;
    margin-top: 50px;
  }

  &__stake {
    flex: 1 0 0;
    background-color: rgba(white, 0.1);
    padding: 10px;
    text-align: left;
  }

  &__total-stake {
    text-align: left;

    &-label,
    &-amount {
      display: inline-block;
    }

    &-amount {
      margin-left: 10px;
    }
  }

  &__stakers-title {
    font-size: 0.9em;
    margin-top: 20px;
    margin-bottom: 5px;
    padding-right: 5px;
    // text-align: right;
  }

  &__stakers {
    background-color: $darkgray;
    padding: 10px;
  }

  &__stakers-header {
    display: flex;
    padding-bottom: 5px;
    border-bottom: 1px solid $gray-2;

    &-address {
      flex: 3 0 0;
    }

    &-amount {
      flex: 1 0 0;
      text-align: right;
    }
  }

  &__stakers-list {
    height: 200px;
    overflow-y: scroll;
    padding-top: 5px;
  }

  &__stake-item {
    display: flex;
    font-family: 'Courier New', Courier, monospace;

    &-address {
      flex: 3 0 0;
      font-size: 0.9em;
    }

    &-amount {
      flex: 1 0 0;
      text-align: right;
    }
  }

  &__sla-graph {
    // height: 200px;
  }

  &__enabled-box {
    text-align: right;
  }

  &__uptime {
    &-amount,
    &-label {
      display: inline-flex;
    }

    &-amount {
      font-size: 1.4em;
    }

    &-label {
      margin-left: 4px;
    }
  }

  &__lists {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    flex-wrap: wrap;
    margin-top: 40px;
  }

  &__bots {
    flex: 1;

    &-title {
      text-align: left;
      font-size: 1.6em;
      text-shadow: 0px 0px 6px white, 0px 0px 0px white;
      padding: 10px;
    }

    .BotList__body {
      max-height: 400px;
      overflow: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: $gray-2;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }
    }
  }

  &__events {
    flex: 1;
  }

  &__loader {
    display: none;
  }

  &--loading {
    .ScanNodePage__loader {
      display: block;
    }

    .ScanNodePage__lists {
      display: none;
    }

    .ScanNodePage__stats {
      display: none;
    }
  }

  &--error {
    .ScanNodeActions {
      display: none;
    }
  }

  @media screen and (max-width: 1200px) {
    .ScanNodePage__lists {
      display: block;
    }
    .ScanNodePage__bots {
      margin-bottom: 30px;
    }
  }
}
