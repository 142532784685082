@use 'sass:color';
@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.BotDocumentation {
  $module: &;

  margin-top: 30px;
  text-align: left;
  width: 100%;

  &__tabs {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }

  &__tab {
    @include transition-fast();

    padding: 10px 30px;
    min-width: 120px;
    font-size: 16px;
    font-weight: 500;
    color: $neutral-50;
    background-color: $gray-4;
    border-radius: 6px;

    &:hover {
      color: $neutral-90;
      background-color: color.scale($gray-4, $lightness: 3%);
    }

    &:active {
      background-color: color.scale($gray-4, $lightness: 13%) !important;
    }

    &--active {
      color: $neutral-100 !important;
      background-color: color.scale($gray-4, $lightness: 8%) !important;
    }
  }

  &__documentation {
    display: flex;
  }

  &__markdown-container {
    width: 80%;
    padding: 30px;
    background-color: $gray-4;
    border-radius: 10px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;

    #{$module}__markdown {
      background-color: transparent;

      pre {
        background-color: $neutral-15;
      }
    }
  }

  &__skeleton {
    line-height: 170%;
  }

  &__scroll {
    @include transition-slow();

    flex: 1;
    display: flex;
    justify-content: center;
    background-color: color.scale($grey-1, $lightness: -10%);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    padding-bottom: 40px;
    cursor: auto;

    &:not(:disabled) {
      cursor: pointer;

      &:hover {
        background-color: color.scale($grey-1, $lightness: 3%);
      }
    }

    &-label {
      display: flex;
      gap: 8px;
      position: sticky;
      top: 40px;
      margin-top: 40px;
      color: $neutral-50;
      font-weight: 500;
    }
  }

  @media #{$breakpoint-md-down} {
    &__markdown-container {
      width: 100%;
    }

    &__scroll {
      display: none;
    }
  }
}
