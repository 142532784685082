.CopyPopover {
  $module: &;

  &__container {
    // empty
  }

  &--ellipsis {
    width: 100%;

    #{$module}__container {
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:hover,
  &:focus {
    opacity: 0.8;
  }
}

.copy-icon {
  outline: none;
  display: inline-block;
  margin-left: 10px;
}
