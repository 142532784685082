@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.BotOverview {
  $module: &;

  &__info {
    display: grid;
    grid-template: 'metadata network counters';
    grid-template-columns: 1fr 1fr auto;
    gap: 30px;
    margin-top: 40px;
    margin-bottom: 30px;

    #{$module}__metadata {
      grid-area: metadata;
    }

    #{$module}__network {
      grid-area: network;
    }

    #{$module}__counters {
      grid-area: counters;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 24px;
      width: 350px;
    }

    @media #{$breakpoint-md-down} {
      grid-template: 'counters counters' 'metadata network';
      grid-template-columns: 1fr 1fr;

      #{$module}__counters {
        flex-direction: row;
        justify-content: flex-start;
        width: unset;

        > * {
          width: 100%;
          max-width: 274px;
          min-height: 100px;
        }
      }
    }

    @media #{$breakpoint-sm-down} {
      grid-template: 'counters' 'metadata' 'network';

      #{$module}__counters {
        flex-direction: column;

        > * {
          max-width: 100%;
        }
      }
    }
  }

  &__charts {
    display: flex;
    gap: 30px;

    #{$module}__calendar-chart {
      flex: 5;
    }

    #{$module}__severity-chart {
      flex: 2;
    }

    @media #{$breakpoint-md-down} {
      flex-direction: column;

      #{$module}__calendar-chart {
        display: none;
      }

      #{$module}__severity-chart {
        flex: 2;
        min-width: unset;
      }
    }
  }

  &__skeleton {
    display: flex;
    flex: 1;
    height: 200px;
  }
}

.BotOverviewBlock {
  padding: 30px;
  font-family: $font-display;
  background: $gray-4;
  text-align: left;
  border-radius: 10px;
}

.BotOverview .BotMetadata {
  @extend .BotOverviewBlock;

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral-100;
    margin-bottom: 20px;
  }

  &__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-family: $font-text;

    > li {
      padding: 0;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }

  .MetadataItem {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 400;
    color: $neutral-90;

    &__value {
      display: flex;
      gap: 6px;
      align-items: center;
    }
  }
}

.BotOverview .BotNetworkInfo {
  @extend .BotOverviewBlock;

  &__title {
    font-size: 18px;
    font-weight: 500;
    color: $neutral-100;
    margin-bottom: 20px;

    &--operators {
      margin-bottom: 8px;
    }
  }

  &__description {
    font-size: 16px;
    color: $neutral-70;
  }

  &__networks {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    list-style: none;
    max-width: 400px;
  }

  &__network {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 5px 10px;
    gap: 6px;
    border-radius: 4px;
    background: $neutral-25;
    font-size: 13px;
    font-weight: 500;

    &-icon {
      width: 18px;
      height: 18px;
    }
  }

  &__section {
    margin-bottom: 30px;
  }
}

.FancyCounter {
  padding: 25px;
  background: $gray-4;
  font-family: $font-display;
  text-align: left;
  min-height: 130px;
  border-radius: 10px;

  &__header {
    display: flex;
    gap: 10px;
    margin-bottom: 25px;
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &__label {
    font-size: 18px;
    font-weight: 500;
    color: $neutral-90;
  }

  &__info {
    margin-left: auto;
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__value {
    font-size: 28px;
    font-weight: 600;
    color: $neutral-90;
    line-height: 100%;
  }

  &__period {
    font-size: 16px;
    font-weight: 500;
    color: $neutral-60;
    line-height: 100%;
  }
}

.BotOverviewChart {
  @extend .BotOverviewBlock;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;
    font-size: 18px;
    font-weight: 500;
    color: $neutral-100;
    margin-bottom: 20px;

    @media #{$breakpoint-md-down} {
      flex-direction: column;
    }
  }

  &__year-controls {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    color: $neutral-50;
  }

  &__body {
    position: relative;
    height: 200px;
  }

  &__empty-state {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: $lightgray-2;
    height: 100%;
    font-size: 20px;
    margin-top: -20px;
  }
}
