@import 'src/common/styles/colors';

.ScannerPoolPage {
  &__scan-nodes {
    margin-top: 40px;
  }
}

.ScannerPoolIdCard {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 50px;

  &__content {
    display: flex;
    align-items: center;

    img {
      width: 8rem;
      height: 8rem;
    }
  }

  &__textContainer {
    margin-left: 2.5rem;
    flex-direction: column;
    justify-items: start;
  }

  &__title {
    font-size: 24px;
    line-height: 140%;
    font-weight: 500;
    color: $neutral-50;
    text-align: left;
  }

  &__id {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 6px;
    color: $neutral-100;
  }

  &__owner {
    font-size: 16px;
    line-height: 140%;
    text-align: left;
    font-weight: 300;
    color: $neutral-50;
  }
}
