@import 'src/common/styles/colors';

.ScannerPoolInfo {
  background-color: $gray-2;
  border-radius: 12px;
  height: 100%;
  padding: 32px;
  text-align: left;
  width: 100%;
  margin-top: 10px;

  &__grid {
    display: grid;
    grid-auto-rows: minmax(20px, auto);
    grid-template-columns: auto auto;
    row-gap: 10px;
    text-align: left;
  }

  &__grid-buffer {
    grid-column: span 2;
    grid-row: span 1;
  }

  &__row {
    column-gap: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  &__item-social {
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  &__item-icon {
    align-items: center;
    display: flex;
    margin-right: 10px;
    height: 20px;
    width: 20px;
  }

  &__item-value {
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;

    &--entry {
      color: $neutral-50;
      grid-column: span 1;
      grid-row: span 1;
    }

    &--edit {
      color: $neutral-50;
      margin-left: 16px;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 20px;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 600;
    grid-column: span 1;
    grid-row: span 1;
    line-height: 24px;
  }

  &__description {
    color: $neutral-50;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    padding-bottom: 30px;
  }

  &__divider {
    background-color: $gray;
    height: 1px;
    margin-bottom: 20px;
    width: 100%;
  }
}
