@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.DataPlanList {
  $module: &;

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    gap: 20px;
    max-width: 880px;

    @media #{$breakpoint-sm-down} {
      grid-template-columns: 1fr;
    }
  }

  &__data-plan {
    width: 100%;

    @media #{$breakpoint-sm-down} {
      max-width: 100%;
    }
  }

  &__general-plan {
    border: 1px solid rgba(18, 92, 92, 0.4) !important;
    background: linear-gradient(141deg, rgb(14 36 30 / 26%) 0%, rgb(0 0 0 / 15%) 100%) !important;
  }

  &__buy-button {
    min-width: 100px;
  }
}
