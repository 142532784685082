@import 'src/common/styles/colors';

.AddNodePool {
  &__modal {
    min-height: 300px;
    padding-left: 60px;
    overflow: visible;

    &-description {
      color: $lightgray-2;
    }

    &-chain {
      padding: 20px;

      &-description {
        margin-bottom: 10px;
        font-size: 1.1em;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }
}
