@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.FileSelector {
  $module: &;

  &__box {
    @include transition-medium();

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 120px;
    cursor: pointer;
    font-size: 14px;
    padding: 16px;
    border-radius: 6px;
    border: 2px dashed $neutral-30;

    &:hover {
      border: 2px dashed $neutral-40;
    }

    &:active {
      border: 2px dashed $blue;
      background-color: rgba(255, 255, 255, 0.02);
    }
  }

  input {
    display: none;
  }

  &__label {
    display: inline-block;
  }

  &__upload-text {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    color: $neutral-45;
  }

  &__upload-icon {
    margin-right: 6px;
  }

  &__tooltip {
    margin-left: 6px;
  }

  &__file-name {
    color: $neutral-90;
    font-weight: 500;
    font-size: 16px;
  }

  &__subtext {
    color: red;
    font-size: 0.8em;
    margin-top: 6px;
  }
}
