@import 'src/common/styles/colors';

.DiscoverPage {
  &__search {
    padding: 0 35px;
  }

  &__content {
    margin-top: 30px;

    &-see-all-button {
      width: 220px;
      height: 50px;
      border: none;
      outline: none;
      color: #fff;
      background: #111;
      cursor: pointer;
      position: relative;
      z-index: 0;
      border-radius: 10px;
      padding: 10px;
    }

    &-see-all-button:before {
      content: '';
      background: linear-gradient(
        45deg,
        #ff0000,
        #ff7300,
        #fffb00,
        #48ff00,
        #00ffd5,
        #002bff,
        #7a00ff,
        #ff00c8,
        #ff0000
      );
      position: absolute;
      top: -2px;
      left: -2px;
      background-size: 400%;
      z-index: -1;
      filter: blur(5px);
      width: calc(100% + 4px);
      height: calc(100% + 4px);
      animation: glowing 20s linear infinite;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      border-radius: 10px;
    }

    &-see-all-button:active {
      color: #000;
    }

    &-see-all-button:active:after {
      background: transparent;
    }

    &-see-all-button:hover:before {
      opacity: 1;
    }

    &-see-all-button:after {
      z-index: -1;
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: #111;
      left: 0;
      top: 0;
      border-radius: 10px;
    }

    @keyframes glowing {
      0% {
        background-position: 0 0;
      }
      50% {
        background-position: 400% 0;
      }
      100% {
        background-position: 0 0;
      }
    }
  }

  &__list {
    text-align: left;
    padding: 0 35px;
    color: white;
  }

  &__group {
    margin: 20px 0;
  }

  &__group-name {
    padding: 10px 0;
    font-size: 1.3em;
  }

  &__group-list {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 20px;
    justify-content: flex-start;
  }

  &__project {
    width: 130px;
    height: 130px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    background-color: rgba(white, 0.1);
    border-radius: 4px;
    color: white;
    transition: 0.2s all ease-out;
    cursor: pointer;
    user-select: none;

    &:hover {
      transform: scale(1.1);
      box-shadow: 0 0 15px rgba(white, 1);
    }
  }

  &__project-logo {
    text-align: center;
    margin: 10px 0px;

    img {
      display: inline-block;
    }
  }

  &__header {
    color: white;
    text-align: left;
    padding: 0 35px;
  }

  &__title {
    font-size: 2rem;
  }

  &__description {
    color: $lightgray-2;
  }

  &__stats {
    padding: 0 35px;

    &__container {
      padding: 20px;
      margin-top: 40px;
      display: flex;
      justify-content: space-around;
      background-color: rgba(white, 0.15);
      flex-wrap: wrap;
      color: white;
      border-radius: 4px;
    }

    &__stat {
    }

    &__counter {
      font-size: 3em;
    }
  }
}
