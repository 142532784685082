@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.ExplorerHeader {
  #{&}__subscribe-button {
    font-weight: 700 !important;

    @media #{$breakpoint-md-down} {
      width: 100%;
      height: 50px;
    }
  }
}
