@import 'src/common/styles/colors';

.ContractCallMessage {
  padding: 30px;

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    svg {
      width: 60px;
      color: $lightgray-2;
    }
  }

  &__external-link {
    text-align: center;
    padding-top: 10px;

    a {
      display: inline-flex;
      column-gap: 5px;
      align-items: center;
      color: $darkblue;
      transition: all 0.2s ease-out;

      &:hover {
        color: $blue;
      }
    }
  }
}
