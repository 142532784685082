@import 'variables';
@import 'colors';
@import 'mixins/container';

:root {
  color-scheme: dark;
}

.App {
  text-align: center;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $black 100%;

  &__body {
    @include container;

    width: 100%;
    margin-bottom: 140px;
  }
}

.ContractFlowBG {
  background: radial-gradient(50% 50% at -20% 50%, #40abe9 0%, #0f0f0f 100%);
}

.WalletFlowBG {
  background: radial-gradient(
    50% 50% at 120% 50%,
    rgba(48, 245, 141, 0.57) 0%,
    #0f0f0f 100%
  );
}

.Header {
  background-color: transparent;
}

.AlertCode {
  height: calc(100vh - 16rem);
}
