@use './Table.module';

@import 'src/common/styles/colors';

.TableHead {
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  border-radius: var(--table-border-radius) var(--table-border-radius) 0 0;
  background-color: var(--table-head-bg);
}
