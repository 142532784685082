@import 'src/common/styles/colors';

.ScannerEntity {
  display: inline-flex;
  align-items: center;
  word-break: break-word;
  gap: 14px;

  &--link {
    color: $accent-blue-30;

    &:hover {
      text-decoration: underline;
    }
  }

  &__children {
    // fix font alignment
    margin-top: 0.25em;
  }

  .ScannerChain {
    position: relative;

    &__img {
      width: 24px;
      height: 24px;
    }

    &__indicator {
      position: absolute;
      content: '';
      bottom: -4px;
      right: -4px;
      height: 9px;
      width: 9px;
      border-radius: 50%;
      background-color: $neutral-45;
      border: 1px solid $neutral-15;
      box-sizing: content-box;

      &--enabled {
        background-color: #2dff83;
      }
    }
  }
}
