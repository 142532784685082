@import 'src/common/styles/colors';

.VaultWithdrawModal {
  &__container {
    max-width: 100%;
  }

  &__title {
    margin-top: 20px;
  }

  &__description {
    color: $neutral-60;
    margin-top: 10px;
    font-size: 14px;
    margin-bottom: 5px;
    line-height: 160%;
  }

  &__body {
  }

  &__item {
    > * {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 8px;
      flex: 1;

      &:not(:first-child) {
        justify-content: flex-end;
      }
    }
  }
}
