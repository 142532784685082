@import 'src/common/styles/colors';

.AllocateStakeModal {
  &__content {
    display: flex;
    justify-content: space-between;
    column-gap: 80px;
    margin-top: 30px;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    min-width: 300px;
  }

  &__form__select {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    &-label {
      width: 10em;
      padding-right: 1em;
      text-align: right;
    }

    .ButtonGroupMenu {
      margin: 0;
    }
  }

  &__form {
    text-align: right;
  }

  &__form__max,
  &__form-error {
    font-style: italic;
    color: $gray;
    margin-top: 3px;
  }

  &__form__allocation-amount {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
