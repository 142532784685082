@import "src/common/styles/variables";
@import "src/common/styles/colors";


.DepositForm {
  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: $neutral-90;
  }

  &__balance {
    color: $neutral-70;

    > span {
      margin-left: 6px;
    }
  }

  &__allowance {
    display: flex;
    justify-content: center;
    column-gap: 6px;
    margin-top: 10px;
    color: $lightgray-2;

    &-amount {
      color: $lightgray-2;
    }
  }

  &__value-button {

    &:hover {
      color: $neutral-100;
    }
  }

  &__actions {
    margin-top: 30px;

    > * {
      max-width: 100%;
      width: 100%;
    }
  }
}
