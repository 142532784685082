@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.TransactionsWidget {
  position: relative;
  display: inline-block;
  margin-right: 12px;

  &__trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.15);
    padding: 6px 14px;
    min-width: 64px;
    gap: 10px;
    border-radius: 8px;
    font-weight: 500;
    color: $neutral-90;

    .Loader {
      width: 16px;
      margin-top: -3px;
      margin-left: -2px;
    }
  }

  &__list-container {
    margin-top: 10px;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    user-select: none;
    pointer-events: none;
    position: absolute;
    width: 300px;
    right: 0;
    z-index: 2;
  }

  &--expanded {
    .TransactionsWidget__trigger {
      background-color: $purple;
    }

    .TransactionsWidget__list-container {
      opacity: 1;
      pointer-events: auto;
      user-select: auto;
    }
  }
}
