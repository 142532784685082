@import "src/common/styles/colors";

.Severity {
  display: inline-block;
  padding: 0.1rem;
  min-width: 80px;
  text-align: center;
  border-radius: 5px;
  font-size: 12px;
  background-color: $black;
}
