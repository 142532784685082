@import "../../../styles/colors";

.FormField {
  margin-bottom: 15px;

  &__label {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    margin-bottom: 10px;
    color: $neutral-90;
  }

  &__errors {
    font-size: 14px;
    margin-top: 10px;
  }

  &__error {
    color: $red;
  }
}
