@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

$border-radius: 10px;
$padding-x: 20px;
$padding-y: 15px;
$height: 50px;

$border-default-light: $lightgray;
$border-default-dark: $gray;
$border-focus: $blue;

@mixin text-color($color) {
  color: $color;
  // fix text color on Safari
  -webkit-text-fill-color: $color;
}

@mixin box {
  width: 100%;
  padding: $padding-y $padding-x;
  height: $height;
  box-sizing: border-box;
}

@mixin box-style-base() {
  @include transition-medium();

  font-size: 16px;
  font-weight: 400;
  border-radius: $border-radius;
  outline: none;
}

@mixin box-style-light {
  @include text-color($black);

  background-color: $lightgray;
  border: 1px solid $border-default-light;
}

@mixin box-style-gray {
  @include text-color($neutral-100);

  background-color: $neutral-15;
  border: 1px solid transparent;
}

@mixin box-style-dark {
  @include text-color($lightgray);

  background-color: $black;
  border: 1px solid $border-default-dark;
}

@mixin placeholder-light {
  @include text-color($gray);
}

@mixin placeholder-gray {
  @include text-color($neutral-35);
}

@mixin placeholder-dark {
  @include text-color(transparentize($lightgray, 0.7));
}

@mixin icon-light {
  color: $gray-2;
}

@mixin icon-dark {
  color: $lightgray-2;
}

@mixin hover-light {
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

@mixin hover-dark {
  border-color: lighten($border-default-dark, 15%);
  box-shadow: 0 16px 24px -12px transparentize($lightgray, 0.92);
}

@mixin focus {
  outline: none;
  border: 1px solid $border-focus;
  caret-color: $blue;
}

@mixin disabled {
  opacity: 0.5;
}

@mixin invalid {
  border: 1px solid $red !important;
  caret-color: $red !important;
}
