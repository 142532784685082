@import 'src/common/styles/colors';

.OldHomePage {
  p {
    padding: 0 35px;
    font-size: 0.9rem;
    color: white;
    margin-right: 5px;

    button {
      margin-left: 10px;
      color: $lightgray-2;
      transition: all 0.2s ease-out;

      &:hover {
        color: $blue;
      }
    }
  }
}
