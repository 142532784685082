@use 'sass:color';
@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden;
}

.Modal {
  position: absolute;
  color: white;
  overflow: auto;
  border-radius: 20px;
  outline: none;
  padding: 20px;
  padding-right: 70px;
  top: 100px;
  z-index: 1;
  background: $gray-4;
  box-shadow: 0 0 20px 3px rgb(93 93 93 / 7%);
  border: 1px solid rgb(255 255 255 / 10%);

  &__overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.scale($black, $lightness: 1%, $alpha: -50%);
    backdrop-filter: blur(4px);
    animation-name: overlay-animation;
    animation-duration: 0.3s;
    overflow: auto;
    z-index: $z-index-modal;
    // padding-top: 60px;
  }

  &__close-button {
    position: absolute;
    right: 20px;
    top: 20px;
  }

  &__form {
    margin-top: 30px;
  }

  &__form-field {
    margin-bottom: 20px;
    font-size: 0.8em;
    display: flex;
    align-items: center;
  }

  &__form-field-label {
    display: inline-block;
    width: 40%;
    min-width: 190px;
    padding-right: 20px;
    text-align: right;
  }

  &__form-field-value {
    display: inline-block;
    width: 60%;
    .Input {
      color: black;
      background-color: white;
    }
  }

  &__header {
    h2 {
      font-size: 1.4em;
    }
  }

  &__children {
    font-size: 0.8em;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.6);
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__submit-button {
    display: flex;
    justify-content: flex-start;
    margin-top: 40px;

    button {
      color: white;
      background-color: $purple;
      padding: 10px 20px;
      outline: none;
    }
  }

  &--close-button-hidden {
    padding-right: 20px;
  }
}

@media screen and (max-width: 520px) {
  .Modal {
    padding-right: 20px;
  }

  .Modal__overlay {
    align-items: initial;
  }

  .Modal__form-field {
    flex-wrap: wrap;
  }

  .Modal__form-field-label,
  .Modal__form-field-value {
    width: 100%;
  }
}

@keyframes overlay-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
