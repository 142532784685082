@import '../../../common/styles/colors';

.SavedFilters {
  display: flex;
  column-gap: 20px;
  row-gap: 10px;
  flex-wrap: wrap;

  &__filters {
    display: flex;
    column-gap: 10px;
    row-gap: 10px;
    flex-wrap: wrap;
  }

  &__saved-filter {
    background-color: $gray-2;
    color: white;
    border: 1px solid transparent;
    border-radius: 4px;
    padding: 6px;
    font-size: 0.8em;
    transition: all 0.2s ease-out;
    outline: none;

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &--selected {
      border-color: $blue;
      opacity: 0.9;
    }
  }

  &__edit-button {
    font-size: 0.8em;
    color: $lightgray;
    transition: all 0.2s ease-out;

    &:hover {
      color: $lightgray-2;
    }
  }

  &__bookmark {
    color: darkgray;
    transition: all 0.2s ease-out;
    outline: none;

    &:hover {
      color: $yellow;
    }
  }

  &__popover {
    font-size: 0.8em;
    opacity: 0.8;
    display: flex;

    .InfoPopover__content {
      display: flex;
    }

    .InfoPopover__flyout {
      top: -40px;
    }
  }

  &__modal {
    width: 100%;
    max-width: 600px;

    &-content {
      color: $lightgray-2;
    }
  }

  &__form {
    margin-top: 20px;

    &-items {
      display: flex;
      flex-direction: column;
      row-gap: 30px;
    }

    &-item {
      display: flex;
      position: relative;
      row-gap: 10px;
      column-gap: 10px;

      &__name {
        background-color: $gray-2;
        color: white;
        width: 100%;

        input::placeholder {
          color: $lightgray-2;
        }
      }

      &__value {
        color: $gray;
        word-break: break-all;
      }

      &__remove {
        color: $black;
        transition: all 0.2s ease-out;
        &:hover {
          color: $darkgray;
        }
      }
    }
  }

  &__add-button {
    margin-top: 30px;
    background-color: $lightpurple;
    padding: 5px;
    border-radius: 4px;
    color: white;
    transition: all 0.2s ease-out;

    &:hover {
      opacity: 0.8;
    }
  }
}

@media screen and (max-width: 520px) {
  .SavedFilters__form-item {
    flex-wrap: wrap;
    flex-direction: row-reverse;
  }
}
