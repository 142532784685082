@import 'src/common/styles/colors';

.EndpointSelector {
  &__title {
    display: flex;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  &__type {
    width: 100%;
    display: flex;

    &-item {
      flex: 1;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      row-gap: 5px;
      padding: 10px 25px;
      margin-left: 5px;
      margin-right: 5px;
      transition: all 0.2s ease-out;
      font-size: 1.2em;
      width: 20px;

      &:hover {
        background-color: rgba(white, 0.3) !important;
        color: $black;
      }

      &--selected,
      &--selected:hover {
        background-color: rgba(white, 0.3);
      }
    }
  }

  &__input-text {
    margin-top: 30px;
    width: 100%;

    .Input {
      width: 100%;
    }
  }

  .TelegramInput {
    display: flex;
    column-gap: 15px;

    > * {
      flex: 1;
      width: 100%;
    }
  }
}
