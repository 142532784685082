@import 'src/common/styles/colors';

.StartBuildingCTA {
  color: white;
  text-align: left;
  margin-bottom: 30px;
  padding: 0 35px;

  &__title {
    font-size: 1.8em;
    margin-bottom: 20px;
    font-weight: bold;
  }

  &__items {
    display: flex;
    justify-content: space-between;
    column-gap: 40px;
    flex-wrap: wrap;
    row-gap: 20px;
  }
}

.MyWalletCTA,
.MonitorContractCTA {
  background-color: rgba(white, 0.1);
  padding: 20px;
  border-radius: 8px;
  // border: 1px solid $blue;
  align-items: flex-end;
  text-align: left;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  &__title {
    font-size: 1.6em;
    margin-bottom: 15px;
  }

  &__action {
    width: 100%;
    text-align: right;
    margin-top: 15px;

    &-button {
      background-color: rgba(white, 0.2);
      padding: 10px;
      border-radius: 4px;
      transition: all 0.2s ease-out;

      &:hover {
        background-color: rgba(white, 0.3);
      }
    }

    &.hidden {
      display: none;
    }
  }

  &__action-list {
    display: flex;
    justify-content: flex-end;
    column-gap: 10px;
    width: 100%;
    margin-top: 15px;

    animation-name: monitor-list;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;

    &.hidden {
      display: none;
    }
  }
}

.MyWalletCTA {
  background: linear-gradient(0deg, rgb(45, 8, 88) 10%, rgb(95, 14, 201) 100%);
}

.MonitorContractCTA {
  background: linear-gradient(
    0deg,
    rgba(88, 31, 8, 1) 10%,
    rgba(201, 67, 14, 1) 100%
  );
}

.ContractModal {
  display: flex;
  max-width: 700px;
  width: 100%;

  &__container {
    padding: 0;
  }

  &__right-side {
    flex: 2;
    padding: 20px;
  }

  &__left-side {
    flex: 1;
  }

  &__left-side {
    background-color: $orange;
    color: white;
    border-right: 4px solid white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__list-container {
    padding: 20px;
  }

  &__description {
    width: 100%;
    background-color: rgba(white, 0.2);
    padding: 10px 20px;
  }

  &__right-side-title {
    font-size: 1.3em;
    margin-bottom: 10px;
  }

  &__address {
    margin-bottom: 20px;
  }

  &__list-title {
    font-size: 1.4em;
  }

  &__list {
    li {
      margin-top: 10px;
      display: flex;
      align-items: center;
      column-gap: 5px;
      align-items: flex-start;

      a {
        display: inline-block;
      }
    }
  }

  &__submit {
    text-align: center;
    margin-top: 15px;

    button {
      padding: 5px 20px;
      background-color: $blue;
      font-size: 1.2em;
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media screen and (max-width: 550px) {
  .ContractModal {
    display: block;

    &__left-side {
      border: 0;
    }
  }
}

@keyframes monitor-list {
  from {
    opacity: 0;
    // width: 1%;
  }
  to {
    opacity: 1;
    // width: 100%;
  }
}
