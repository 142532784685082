@use 'sass:color';
@import 'src/common/styles/colors';

.UpdateCommissionModal {
  &__commission-input {
    display: flex;
    justify-content: center;
  }

  &__commission-submit {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  &__commission-form {
    margin-top: 10px;
  }

  &__commission-label {
    color: $lightgray-2;
  }

  &__current-commission {
    color: $gray;
    margin-top: 30px;
    font-size: 1.2em;

    &-amount {
      color: $blue;
    }
  }
}

.ScanNodePoolActionsButton {
  display: flex;
  justify-content: center;

  .ActionButton {
    &__button {
      border-radius: 6px 0 0 6px;
      padding-top: 0.875rem;
      padding-bottom: 0.875rem;
      padding-left: 3.5rem;
      padding-right: 3.5rem;
      font-size: 1.125rem;
      line-height: 1.75rem;
      font-weight: 600;
    }

    &__disabled-text {
      color: $gray;
      font-style: italic;
      font-size: 0.875rem;
      text-align: right;
    }
  }

  &__options-dropdown {
    display: flex;
    justify-content: center;
    // margin-top: 30px;
    position: relative;
    // overflow: hidden;
  }

  &__options-button {
    background-color: color.scale($secondary-purple, $lightness: 4%);
    border-radius: 0px 6px 6px 0;
  }

  &__options {
    display: flex;
    flex-direction: column;
    position: absolute;
    width: 200px;
    top: 50px;
    right: 0;
    margin-top: 6px;
    padding: 6px 0;
    border-radius: 6px;
    background-color: $neutral-15;
    overflow: hidden;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);

    &__item {
      width: 100%;
      justify-content: center;
      display: flex;
      color: $lightgray-2;
      background-color: $neutral-15;
      border-radius: 0 !important;

      .Button,
      .Button:hover {
        color: $lightgray-2;
        background: transparent;
        width: 100%;
        height: 40px;
        justify-content: flex-end;
        padding-right: 20px;
      }

      &:not(:disabled):hover {
        background-color: #343434;
      }
    }
  }

  &__option-button {
    border-radius: 0;
  }
}
