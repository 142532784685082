@use 'sass:color';
@import 'src/common/styles/mixins/transitions';
@import 'src/common/styles/colors';

.AutocompleteInput {
  position: relative;

  &__suggestion-list {
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
  }
}

.SuggestionList {
  $module: &;

  @include transition-fast();

  display: none;
  color: $lightgray;
  background: $neutral-20;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  overflow-y: auto;
  overflow-x: hidden;
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.15);
  border: 1px solid rgb(255 255 255 / 5%);
  z-index: 1;

  &__container {
    position: relative;
  }

  &__list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  &__item {
    margin: 0;
    padding: 10px 20px;
    color: $lightgray;
    text-align: left;
    cursor: pointer;

    &--highlighted {
      background-color: lighten($gray-2, 6%);
    }

    &--selected {
      color: $neutral-100;
      background-color: $darkblue;
    }
  }

  &__empty-state {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    color: $neutral-60;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100px;
  }

  &--visible {
    display: block;
  }
}
