@import 'src/common/styles/colors';

.WithdrawModal {
  &__container {
    max-width: 600px;
    width: 100%;
    padding-right: 20px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 140px;
    height: 100%;
  }

  .InformationBox {
    max-width: 100%;
    margin-top: 20px;

    &__item-label,
    &__item-value {
      flex: 1;
    }

    &__item-value {
      justify-content: flex-end;

      .InfoPopover {
        margin-left: 8px;
      }

      .button-cta-2 {
        padding: 5px 10px;

        .Loader {
          margin: 0 20px;
          width: 20px;
        }
      }
    }
  }

  &__stake-amount {
    display: flex;
    svg {
      display: inline-block;
      margin-left: 5px;
    }
  }

  .button-cta-2:disabled {
    background-color: $gray;
    font-style: italic;
    opacity: 0.6;
    cursor: default;
  }
}

.ToInactiveModal {
  &__container {
    width: 100%;
    max-width: 500px;
    padding-right: 20px;
  }

  &__description {
    margin: 20px 0;
  }

  &__input-group {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
  }

  &__input {
    position: relative;
    display: inline-block;
  }

  &__amount-label {
    display: inline-block;
    font-size: 1.3em;
    position: absolute;
    top: 8px;
    right: 10px;
    color: #a1a1aa;
  }

  &__max {
    display: inline-block;
    margin-left: 10px;

    button {
      background-color: $yellow;
      color: black;
      padding: 2px 5px;
      border-radius: 5px;
      transition: all 0.2s ease-out;
      font-variant: small-caps;
      text-transform: uppercase;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  .button-cta-2:disabled {
    background-color: $gray;
    font-style: italic;
    opacity: 0.6;
    cursor: default;

    .Loader {
      margin: 0 20px;
      width: 20px;
    }
  }
}
