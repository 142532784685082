@import 'src/common/styles/colors';

.DelegationPage {
  color: white;

  &__tabs {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
