@import 'src/common/styles/colors';

.markdown-body {
  background-color: $neutral-10;
  color: $neutral-90;

  ol p,
  ul p {
    display: inline;
  }

  ul {
    list-style-type: disc;
    list-style-position: inside;
  }
  ol {
    list-style-type: decimal;
    list-style-position: inside;
  }
  ul ul,
  ol ul {
    list-style-type: circle;
    list-style-position: inside;
    margin-left: 15px;
  }
  ol ol,
  ul ol {
    list-style-type: lower-latin;
    list-style-position: inside;
    margin-left: 15px;
  }

  pre {
    background-color: $darkgray;
  }

  table {
    tr {
      background-color: darken($darkgray, 2%);

      &:nth-child(2n) {
        background-color: darken($darkgray, 4%);
      }
    }
  }
}
