@use 'sass:color';

@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.Switch {
  $module: &;

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  position: relative;
  cursor: pointer;

  &__input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &__label {
    font-size: 16px;
    color: $neutral-50;
  }

  &__slider {
    @include transition-slow();

    position: relative;
    display: block;
    height: 28px;
    width: 54px;
    border-radius: 30px;
    background-color: $neutral-30;

    &::before {
      @include transition-slow();

      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      margin: 0 4px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      background-color: $neutral-50;
      transform: translateY(-50%);
    }

    &--on {
      background-color: $secondary-purple !important;

      &::before {
        transform: translateY(-50%) translateX(25px);
        background-color: $neutral-90;
      }
    }
  }

  &:hover {
    #{$module}__slider {
      background-color: color.scale($neutral-30, $lightness: 10%);
    }
  }

  &--disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
}
