@import 'src/common/styles/colors';

.AlertTableHeading {
  display: inline-block;
  align-items: center;
  padding: 0.5rem 0;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  width: 200px;
  --tw-text-opacity: 1;
  color: white;
  flex: 7 0 0%;
  text-align: left;
  outline: none;
  background-color: transparent;
  border-bottom: 1px solid $gray;

  svg {
    display: inline;
  }

  &--sortable {
    font-weight: bold;
    cursor: pointer;
  }
}
