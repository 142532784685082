@use './Table.module';

@import 'src/common/styles/colors';

.TableHeading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  height: 100%;
  width: 100%;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  font-size: 14px;

  &:last-child {
    border-right: none;
  }

  &__title {
    font-weight: 500;
    font-size: 0.9rem;
    line-height: 14px;
    color: $lightgray-3;
    text-align: left;
    padding: 14px Table.$padding;
  }

  &__title + &__sub-headings {
    margin-top: -14px;
  }

  &__sub-headings {
    display: flex;
  }

  &__tooltip {
    margin-left: 4px;
    color: red;
  }
}

.TableCheckableHeading {
  width: 46px;
  border-right: 0;
}
