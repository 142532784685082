@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.SuccessDataPlanModal {
  width: 100%;
  max-width: 500px;
  padding: 0;

  &__wrapper {
    padding-right: 40px;
  }

  &__title {
    font-size: 2.4em;
    font-weight: bold;
    margin-bottom: 0.1em;
    padding: 15px;
    text-align: center;
  }

  &__description {
    font-size: 1.1em;
    padding-left: 20px;
  }

  &__action {
    text-align: center;
    margin-top: 25px;
  }

  .Button {
    padding: 0 28px;
  }
}
