@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.TransactionTable {
  border-radius: 6px;
  text-align: left;

  &__cell-txid,
  &__cell-date,
  &__cell-transactionType,
  &__cell-amount {
    padding: 14px 23px;
  }

  &__row {
    height: 70px;
  }

  .TableHeading__sub-headings {
    display: none;
  }

  &__cell-amount {
    color: $neutral-90;

    &--increment {
      color: $green;
    }
  }

  &__cell-txid {
    font-family: 'Courier New', Courier, monospace;
  }

  &__title {
    font-size: 1.2em;
    font-weight: 500;
    margin-bottom: 1em;
  }

  &__load-more {
    width: 100%;
    height: 100%;
  }

  .Table__container > .TableBody > &__load-more-row {
    border-left: 1px solid rgba(255, 255, 255, 0.1);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 0px 0px 12px 12px;
  }
}
