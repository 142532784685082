@import 'src/common/styles/colors';

.Message {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 10px;

  &--error {
    background-color: rgba(red, 0.5);
  }
}
