@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.Pagination {
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  &__label {
    color: $gray;

    &-number {
      color: white;
      margin: 0rem 0.3rem;
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  &__nav-button {
    @include transition-medium();

    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    width: 32px;
    background-color: $neutral-30;
    border-radius: 4px;

    &:not(:disabled) {
      &:hover {
        background-color: $neutral-35;
      }
    }
  }

  @media screen and (max-width: 460px) {
    &__label {
      display: none;
    }
  }
}
