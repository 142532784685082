@import 'src/common/styles/colors';

.react-select-container {
  .react-select {
    &__control {
      transition: border-color 0.2s ease-out;
      background-color: $gray-2;
      border: 1px solid transparent;
      border-radius: 5px;
      box-shadow: none;

      &:hover,
      &:focus {
        border-color: $blue;
      }
    }

    &__input {
      color: white;
    }

    &__value-container {
      -webkit-box-align: center;
      align-items: center;
      display: flex;
      flex: 1 1 0%;
      flex-wrap: wrap;
      padding: 2px 1em 2px 8px;
      position: relative;
      overflow: hidden;
      box-sizing: border-box;

      .ValueContainer__children {
        font-size: 0.875rem;
        line-height: 1.25rem;
        align-items: center;
        display: flex;
      }

      .ValueContainer__icon {
        color: rgb(161, 161, 170);
        line-height: 1;
        margin-left: 0.5rem;
        margin-right: 0.5rem;
      }
    }

    &__menu {
      animation-name: filter-menu;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
      margin-top: 20px;
      // width: 100%;
      background-color: black;
    }
  }
}

@keyframes filter-menu {
  from {
    opacity: 0;
    margin-top: 20px;
  }
  to {
    opacity: 1;
    margin-top: 5px;
  }
}

.SelectFilter {
  cursor: pointer;

  &__container {
    position: relative;

    &.errored {
      .SelectFilter__subtext {
        color: red;
      }

      .react-select__control {
        border-color: red;
      }
    }
  }

  &__subtext {
    position: absolute;
    font-size: 0.8em;
    bottom: -20px;
    left: 10px;
  }
}

.SelectFilter {
  .react-select__placeholder {
    color: rgb(209, 213, 219);
    margin-left: 2px;
    margin-right: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: contents;
  }

  .react-select__value-container {
    cursor: pointer;
  }
}
