@import 'src/common/styles/colors';

.AlertRow {
  display: flex;
  --tw-text-opacity: 1;
  color: $lightgray;
  font-size: 0.875rem;
  line-height: 1.25rem;
  animation-name: row-animation;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  border-bottom: 1px solid $gray;
  padding: 0.6rem 0;
  height: 44px;

  svg {
    display: inline;
  }

  &:hover {
    background-color: rgba(white, 0.1);
    box-shadow: inset 4px 0px 0 $blue;
  }

  &__severity,
  &__name,
  &__description,
  &__dates,
  &__actions,
  &__scanners {
    text-align: left;
    flex: 7 0 0%;
    overflow: auto;
    word-break: break-word;
  }

  &__description,
  &__name {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #555;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  &__severity {
    &-label {
      display: inline-block;
      padding: 0.1rem;
      min-width: 80px;
      text-align: center;
      border-radius: 5px;
      font-size: 12px;
      background-color: $black;
    }
  }

  &__time {
    color: $lightgray-2;
  }

  &__actions {
    text-align: center;
    overflow: initial;
  }

  &__name {
    color: rgb(197, 176, 176);
    display: inline-flex;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: baseline;

    button {
      transition: all 0.2s ease-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  @media screen and (max-width: 360px) {
    &__name button {
      display: none;
    }
  }

  @media screen and (max-width: 720px) {
    &__dates {
      font-size: 0.8em;
    }
  }

  &__external-link {
    text-align: right;

    img {
      border-radius: 50%;
      transition: all 0.2s ease-out;

      &:hover {
        box-shadow: 0px 0px 15px 0px rgba(white, 0.5);
      }
    }
  }
}

@keyframes row-animation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
