@import 'colors';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  height: 100%;
}

@font-face {
  font-family: 'neuehaas';
  src: url('fonts/NeueHaasTextRegular.ttf') format('truetype'),
    url('fonts/NeueHaasTextRegular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'neuehaas';
  src: url('fonts/NeueHaasTextMedium.ttf') format('truetype'),
    url('fonts/NeueHaasTextMedium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'neuehaas';
  src: url('fonts/NeueHaasTextBold.ttf') format('truetype'),
    url('fonts/NeueHaasTextBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'neue-haas-display';
  src: url('fonts/NeueHaasDisplayBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'neue-haas-display';
  src: url('fonts/NeueHaasDisplayMedium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'neue-haas-display';
  src: url('fonts/NeueHaasDisplayRegular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'neuehaas', Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  overflow-y: scroll;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.button-cta {
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.8em;
  background: linear-gradient(
    270deg,
    rgba(68, 140, 251, 1) 10%,
    rgb(29, 161, 87) 100%
  );
  background-color: rgba(68, 140, 251, 1);
  background-repeat: no-repeat;
  color: white;
  padding: 5px 10px;
  border-radius: 15px;
  transition: all 0.2s ease-in;
  cursor: pointer;
}

.button-cta:hover {
  background-position: -80px;
}

.color-purple {
  color: $purple;
}
