@use '../Input/Input.mixins' as Input;

@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

$module: '.Select';

.SelectContainer #{$module} {
  &__control {
    @include Input.box-style-base();
    @include Input.box-style-dark();
    @include transition-medium();

    height: Input.$height;
    width: 100%;
    padding-left: 20px;

    &:hover:not(&--is-focused) {
      @include Input.hover-dark();
    }

    &--is-focused {
      @include Input.focus();

      box-shadow: none;

      #{$module}__indicator {
        color: lighten($gray, 15%);
      }
    }

    &--is-disabled {
      @include Input.disabled();
    }

    &--is-invalid {
      @include Input.invalid();
    }
  }

  &__icon {
    @include Input.icon-dark();

    padding-right: 8px;
  }

  &__value-container {
    padding-left: 0px;
    font-size: 16px;

    #{$module}__multi-value {
      background-color: $neutral-30;
    }
  }

  &__indicator {
    color: $gray;
    padding: 13px 16px;

    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      color: $lightgray-2;
    }
  }

  &__dropdown-indicator {
    padding-right: 20px;
  }

  &__indicator-separator {
    height: 25px;
    margin-top: auto;
    margin-bottom: auto;
    background-color: $gray;
    width: 1px;
  }

  &__input {
    color: $gray;
  }

  &__single-value {
    color: $lightgray;
  }
}

#{$module}__menu-portal {
  z-index: 9999 !important;
}

#{$module}__menu-portal, .SelectContainer #{$module} {
  #{$module}__menu {
    color: $lightgray;
    background-color: $gray-2;
    border-radius: 10px;

    &-list {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    #{$module}__option {
      text-align: left;
      padding: 10px 20px;
      color: $lightgray;

      &--is-focused {
        background-color: lighten($gray-2, 6%);
      }

      &--is-selected {
        background-color: $darkblue;
      }
    }
  }
}
