@import 'src/common/styles/colors';

.EventList {
  &__filters {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: baseline;
  }

  &__title {
    font-size: 1.6em;
  }

  &__include-links {
    display: flex;
    align-items: center;
    column-gap: 5px;

    input {
      appearance: none;
      margin-right: 10px;
      // background-color: red;
      font: inherit;
      color: currentColor;
      width: 1.15em;
      height: 1.15em;
      border: 0.15em solid currentColor;
      border-radius: 0.15em;
      transform: translateY(-0.075em);
      display: grid;
      place-content: center;
    }

    input[type='checkbox']::before {
      content: '';
      width: 0.65em;
      height: 0.65em;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      box-shadow: inset 1em 1em white;
      transform-origin: bottom left;
      clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
    }

    input[type='checkbox']:checked::before {
      transform: scale(1);
    }
  }

  &__heading {
    background-color: #19191a;
    display: flex;
    text-align: left;
    padding: 20px 20px;
    font-size: 0.75rem;
    line-height: 1rem;
    justify-content: space-between;
    text-transform: uppercase;
  }

  &__content {
    height: 400px;
    overflow: auto;
    position: relative;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  &__event {
    display: flex;
    padding: 14px 20px;
    background-color: $darkgray;

    &:nth-child(odd) {
      background-color: $gray-2;
    }

    &-type {
      flex: 10;
      text-align: left;
    }

    &-date {
      flex: 6;
      text-align: right;
    }

    &-stake {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    &-action {
      width: 40px;
      display: flex;
      justify-content: right;
    }

    &-detail {
      font-size: 0.9em;
      margin-left: 20px;
      font-style: italic;
      color: $gray;
    }
  }

  &__loading {
    position: absolute;
    top: 0;
    padding-top: 10px;
    width: 100%;
    height: 100%;
    backdrop-filter: blur(2px);

    &-text {
      color: $lightgray-2;
      font-size: 0.9em;
      margin-top: 10px;
    }
  }

  &__load-more {
    background-color: $gray-2;
    border-radius: 4px;
    padding: 10px 0;
    width: 150px;
    margin-top: 20px;

    .Loader {
      width: 20px;
    }

    &--no-events {
      background-color: transparent;
      font-style: italic;
      color: $gray;
    }

    &:disabled {
      cursor: default;
    }
  }
}
