@import 'src/common/styles/colors';

.InfoPopover {
  display: inline-flex;

  &--marginLeft {
    margin-left: 5px;
  }

  &__onClick {
    &:hover {
      cursor: pointer;
    }
  }
}
