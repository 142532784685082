$pink: #f9007f;
$red: #ff003c;
$orange: #ff5411;
$orange-2: #ff7733;
$yellow: #ffd331;
$lime: #bdff3d;
$green: #02e369;
$cyan: #0fdefc;
$cyan-2: #1ffddc;
$blue: #0770fb;
$darkblue: #165bbd;
$purple: #6855fa;
$lightpurple: rgb(129, 140, 248);
$magenta: #ce00e8;
$black: #020303;
$grey-1: #111112;
$gray-4: #646568;
$gray-3: #222222;
$gray-2: #272727;
$gray-4: #111112;
$darkgray: #1b1b1c;
$gray: #71717a;
$lightgray: #f1f1f1;
$lightgray-2: #d1d5db;
$lightgray-3: #9e9e9e;

// new color system
$neutral-10: #0f0f0f;
$neutral-15: #222222;
$neutral-20: #242627;
$neutral-25: #313131;
$neutral-30: #444444;
$neutral-35: #53585b;
$neutral-40: #707070;
$neutral-43: #7d8082;
$neutral-45: #a8a8a8;
$neutral-50: #b6b6b6;
$neutral-60: #c1c1c1;
$neutral-70: #dadada;
$neutral-75: #c1c1c1;
$neutral-90: #e9e9e9;
$neutral-100: #fff;
$accent-pink-10: #d82c55;
$accent-green-40: #30f98d;
$accent-green-50: #1affd9;
$accent-blue-30: #247dfb;
$accent-blue-40: #40abe9;
$accent-blue-50: #4cd2ff;
$secondary-purple: #645af0;
$accent-purple: #a356f1;
