@import 'src/common/styles/colors';
@import 'src/common/styles/variables';
@import 'src/common/styles/mixins/transitions';

.Popover {
  visibility: hidden;
  pointer-events: none;
  z-index: $z-index-modal + 1;

  &--open {
    visibility: visible;
    pointer-events: auto;
  }

  &__content {
    @include transition-medium();

    margin: 0;
    opacity: 0;
    transform: translateY(-8px);
    overflow: auto;
    border-radius: 8px;
    max-height: 100%;
    background-color: $neutral-15;
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);

    &--open {
      opacity: 1;
      transform: translateY(0);
    }
  }
}
