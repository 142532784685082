@import 'src/common/styles/colors';

.ConnectWalletDialog {
  margin-top: 20px;
  min-width: 400px;

  &__container {
    .react-responsive-modal-modal {
      padding: 0;
    }
  }

  &__header {
    padding: 1.2rem;
  }

  &__body {
    padding: 1.2rem;
    background-color: $black;
  }

  &__connector {
    background-color: $darkgray;
    outline: none;
    border: 1px solid transparent;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    margin-top: 0px;
    opacity: 1;
    width: 100% !important;
    margin-bottom: 10px;
    transition: all 0.3s ease-out;

    &:hover {
      cursor: pointer;
      border: 1px solid $blue;
    }

    &-name {
      display: flex;
      flex-flow: row nowrap;
      color: white;
      font-size: 1rem;
      font-weight: 500;
    }

    &-logo {
      display: flex;
      flex-flow: column nowrap;
      -webkit-box-align: center;
      align-items: center;
      -webkit-box-pack: center;
      justify-content: center;
      height: 24px;
      width: 24px;
    }

    &--current {
      background-color: $black;
      border: 1px solid transparent;
      cursor: default;
      pointer-events: none;

      &:hover {
        cursor: default;
        border: 1px solid transparent;
      }

      .ConnectWalletDialog__connector-name::before {
        content: '•';
        font-size: 30px;
        color: #27ae60;
        line-height: 17px;
        margin-right: 3px;
      }
    }
  }
}
