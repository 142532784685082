@import 'src/common/styles/variables';

@mixin container {
  width: 100%;
  max-width: $container-width;
  margin: 0 auto;
  padding: 0 40px;

  @media #{$breakpoint-md-down} {
    padding: 0 20px;
  }
}
