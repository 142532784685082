@import "src/common/styles/colors";

.Period {
  &__container {
    padding: 16px;
    border-radius: 10px;
    background-color: $neutral-10;
  }

  &__row {
    margin-bottom: 15px;
  }

  &__subtitle {
    margin-top: 0;
    margin-bottom: 10px;
  }

  &__dates {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
  }

  &__duration {
    color: $neutral-50;
    font-weight: normal;
  }

  &__shortcuts {
    display: flex;
    flex-direction: row;
    gap: 8px;
    margin-bottom: 16px;
    overflow-x: auto;
    padding: 1px;
  }

  &__controls {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    gap: 12px;
    margin-top: 16px;
  }

  &__apply-button {
    width: 100%;
    max-width: 120px;
  }

  .flatpickr-calendar {
    background-color: transparent;
    box-shadow: none;
  }
}
