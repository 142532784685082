@import 'src/common/styles/colors';

.ProjectSelector {
  & &__control {
    transition: border-color 0.2s ease-out;
    background-color: $gray-2;
    border: 1px solid transparent;
    border-radius: 5px;
    box-shadow: none;

    &:hover,
    &:focus {
      border-color: $blue;
    }
  }

  & &__input {
    color: white;
  }

  & &__value-container {
    -webkit-box-align: center;
    align-items: center;
    display: flex;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 1em 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
  }

  & &__menu {
    animation-name: filter-menu;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    margin-top: 20px;
    // width: 100%;
    background-color: black;
  }

  & &__menu-list {
    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #888;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  & &__option {
    background-color: black;
    cursor: pointer;

    &:hover {
      background-color: $gray-2;
    }
  }

  & &__multi-value {
    background-color: $darkgray;
  }

  & &__multi-value__label {
    color: white;
  }

  & &__multi-value__remove:hover {
    color: red;
    background: none;
    cursor: pointer;
  }
}

@keyframes filter-menu {
  from {
    opacity: 0;
    margin-top: 20px;
  }
  to {
    opacity: 1;
    margin-top: 5px;
  }
}
