@import 'src/common/styles/colors';
@import 'src/common/styles/variables';
@import 'src/common/styles/mixins/typography';

.Intro {
  position: relative;
  text-align: left;
  color: white;

  &__title {
    @include h1-responsive;

    text-align: left;
    margin-top: 60px;
    margin-bottom: 38px;
    line-height: 130%;
  }

  &__description {
    @include lead-responsive();

    color: $gray;
    margin-bottom: 30px;
    max-width: 800px;
    line-height: 150%;
  }

  &__docs-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 8px;
  }

  @media #{$breakpoint-lg-down} {
    &__title {
      margin-top: 40px;
      margin-bottom: 20px;
    }

    &__description {
      max-width: 640px;
    }
  }

  @media #{$breakpoint-md-down} {
    &__docs-button {
      position: static;
      height: 50px;
      padding-left: 12px;
      padding-right: 12px;
    }

    &__title {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}
