@import 'src/common/styles/colors';

.EnableDisableModal {
  &__description {
    margin: 10px 0;
  }
}

.AddScanNodeModal {
  &__description {
    margin: 10px 0;
  }

  &__button {
    margin-top: 30px;
  }

  &__missing-stake {
    font-style: italic;
    color: $gray;
    font-size: 0.8em;
    margin-top: 5px;
  }

  &__text__input {
    font-size: 0.6em;
    font-family: 'Courier New', Courier, monospace;
    padding: 1em;
    margin-bottom: 20px;

    &--red {
      color: $red;
      -webkit-text-fill-color: $red;
    }

    &--yellow {
      color: $yellow;
      -webkit-text-fill-color: $yellow;
    }

    &--green {
      color: $green;
      -webkit-text-fill-color: $green;
    }
  }

  &__address {
    display: flex;
    column-gap: 5px;
    align-items: center;
    font-family: 'Courier New', Courier, monospace;
  }

  &__error {
    color: $yellow;
    -webkit-text-fill-color: $yellow;
    text-align: center;
    margin-top: 10px;
  }

  .InformationBox {
    max-width: 600px;
    width: 600px;
  }

  &__contract-call {
    margin-top: 15px;
    display: flex;
    justify-content: center;
  }
}
