@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.BotStake {
  margin-top: 40px;
  text-align: left;

  &__container {
    max-width: 450px;
    width: 100%;
    padding: 30px;
    background: $gray-4;
    border-radius: 10px;
  }
}
