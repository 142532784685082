@import 'src/common/styles/mixins/container';
@import 'src/common/styles/colors';

.Footer {
  @include container;

  width: 100%;
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: auto;

  &__company {
    display: flex;
    align-items: baseline;
  }

  &__logo {
    width: 100px;
  }

  &__links {
    display: inline-flex;
    color: #8b8b8b;

    &-item {
      position: relative;

      &:not(:last-child) {
        margin-right: 20px;

        &:after {
          position: absolute;
          content: '';
          right: -12px;
          top: 50%;
          background: #8b8b8b;
          height: 2px;
          width: 4px;
        }
      }

      &:hover {
        color: white;
      }
    }
  }

  &__link {
    &::after {
      content: '|';
      margin: 0 15px;
    }

    &:last-child {
      &::after {
        content: none;
      }
    }

    a {
      transition: color 0.2s ease-out;
      font-family: 'neue-haas-display';
      font-weight: 400;

      &:hover {
        color: white;
      }
    }
  }

  &__discord-link {
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: all 0.2s ease-out;
    font-size: 0.8em;

    &:hover {
      opacity: 0.7;
    }

    img {
      width: 30px;
      margin-left: 10px;
    }

    svg {
      width: 30px;
      margin-left: 10px;
      height: 30px;
      color: white;
    }
  }

  &__node-config {
    z-index: 0;
  }
}

@media screen and (max-width: 600px) {
  .Footer__company .Footer__links {
    display: none;
  }
}

@media screen and (max-width: 1200px) {
  .Footer__node-config {
    display: none;
  }
}
