@import 'src/common/styles/colors';

.AlertsTable {
  $module: &;

  &__head {
    display: flex;
    text-align: left;
  }

  &__body {
    position: relative;
    min-height: 240px;
  }

  &__no-alerts {
    padding-top: 20px;
    color: white;
    text-align: center;
  }

  &__loader {
    position: absolute;
    width: 100%;
    height: 100%;
    top: -1px;
    bottom: 0;
    left: 0;
    overflow: hidden;
    background-color: transparentize($neutral-20, 0.4);

    &__shimmer {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.05) 30%,
        rgba(#fff, 0.1) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 1.7s infinite ease;
      opacity: 0.6;

      @keyframes shimmer {
        100% {
          transform: translateX(100%);
        }
      }
    }
  }

  // At the moment, responsiveness is controlled in a not very explicit way.
  // In the future, we should think of a universal table component with a smart mechanism
  // that allows to control the size, the alignment and the visibility of the columns, like in react-table, for example.

  *[data-name='Severity'] {
    flex: 2 0 0%;
    padding-left: 12px;
  }

  *[data-name='Alert'] {
    flex: 6 0 0%;
  }

  *[data-name='Details'] {
    padding-left: 4px;
  }

  *[data-name='Node Operators'] {
    flex: 2 0 0%;
    text-align: right;
  }

  *[data-name='Date'] {
    flex: 2 0 0%;
    text-align: right;
  }

  *[data-name='Actions'] {
    flex: 1 0 0%;
  }

  @media screen and (max-width: 1200px) {
    *[data-name='Details'],
    *[data-name='Node Operators'] {
      display: none;
    }
  }

  @media screen and (max-width: 720px) {
    *[data-name='Date'] {
      text-align: right;
      padding-right: 10px;
    }

    *[data-name='Alert'] {
      padding-left: 10px;
    }
  }

  @media screen and (max-width: 640px) {
    *[data-name='Severity'] {
      display: none;
    }

    *[data-name='Actions'] {
      display: none;
    }
  }
}
