@import 'src/common/styles/colors';

.ActionCell {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  width: 70px;

  &__container {
    margin: 0 -15px;
  }

  &__button {
    border-radius: 50%;
    background-color: transparent;
  }

  &:last-child {
    border-right: none;
  }

  .BotMenuCell {
    &__menu {
      background-color: $neutral-30;
      border-radius: 12px;
      width: 171px;
      font-size: 18px;
      color: $neutral-50;

      &-item {
        padding: 3.5px 20px;

        &:hover {
          border-radius: 12px;
        }

        &:first-child {
          padding-top: 12px;
        }

        &:last-child {
          padding-bottom: 12px;
        }
      }
    }
  }
}
