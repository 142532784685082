@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.VaultStakingForm {
  &__infobox {
    max-width: 100%;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    margin-top: 10px;
  }

  &__description {
    color: $neutral-60;
    margin-bottom: 20px;
  }

  &__form-container {
    flex: 1;
  }

  &__information {
    flex: 1;
  }

  &__amount-visual {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    margin-bottom: 20px;

    &-title {
      font-size: 1.2em;
    }

    &-graph {
      margin: 0;
      font-size: 150px;
    }
  }

  @media #{$breakpoint-md-down} {
    &__content {
      flex-direction: column;
    }
  }
}

.VaultStakingForm .DepositForm {
  &__header {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 15px;
  }

  &__title {
    font-size: 16px;
    font-weight: 500;
    color: $neutral-90;
  }

  &__balance {
    color: $neutral-70;

    > span {
      margin-left: 6px;
    }
  }

  &__allowance {
    display: flex;
    justify-content: center;
    column-gap: 6px;
    margin-top: 10px;
    color: $lightgray-2;

    &-amount {
      color: $lightgray-2;
    }
  }

  &__value-button {
    &:hover {
      color: $neutral-100;
    }
  }

  &__actions {
    margin-top: 30px;

    > * {
      max-width: 100%;
      width: 100%;
    }
  }
}
