@import 'src/common/styles/colors';

.InformationBox {
  text-align: left;
  max-width: 400px;
  flex: 1;

  &__row {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }

  &__title {
    font-size: 0.625rem;
    line-height: 0.875rem;
    color: rgba(255, 255, 255, 0.64);
    text-transform: uppercase;
    margin: 0px;
    letter-spacing: 1.2px;
    font-weight: 500;
    display: inline-block;
  }

  &__group {
    border-top: 1px solid rgba(white, 0.16);
    margin-top: 0.4rem;
    padding-top: 0.4rem;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-self: center;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 0.4rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  &__item-label {
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;

    &--alignItems {
      display: flex;
      align-items: center;
    }
  }

  &__item-value {
    display: flex;
    align-items: center;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 400;

    small {
      font-size: 0.7em;
      text-transform: uppercase;
      padding-left: 0.4em;
    }
  }
}
