@import 'src/common/styles/colors';

.StakingSubjectSelector {
  padding: 30px 0;

  &__form {
    display: flex;
    column-gap: 20px;
    width: 100%;
    margin-bottom: 20px;
  }

  &__type {
    flex: 1;
  }

  &__value {
    flex: 4;

    .ChainIcon {
      display: inline-block;
      margin-right: 5px;
      width: 24px;
    }
  }

  &__action {
    text-align: center;

    .button-cta-2:disabled {
      background-color: $gray;
      font-style: italic;
      opacity: 0.6;
      cursor: default;

      &.button-cta-2:hover {
        opacity: 0.6;
      }
    }

    p {
      color: $lightgray;
      font-style: italic;
      opacity: 0.6;
      margin-top: 10px;
    }
  }
}
