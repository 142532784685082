@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.ChartTooltip {
  padding: 20px 15px;
  border-radius: 8px;
  border: 2px solid $neutral-40 !important;
  background: rgba(15, 15, 15, 0.7);
  backdrop-filter: blur(25px);
  text-align: left;
  font-family: $font-display;
  min-width: 160px;

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: $neutral-70;
    margin-bottom: 20px;
  }

  &__sub-title {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: $neutral-50;
    margin-top: -17px;
    margin-bottom: 20px;
  }

  &__records {
    display: grid;
    row-gap: 10px;
    column-gap: 60px;
  }
}

.TooltipRecord {
  $module: &;

  min-width: 110px;

  &--row {
    display: flex;
    justify-content: space-between;
    gap: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;

    #{$module}__label {
      color: $neutral-70;
    }

    #{$module}__value {
      color: $neutral-90;
    }
  }

  &--column {
    display: flex;
    flex-direction: column;

    #{$module}__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $neutral-40;
    }

    #{$module}__value {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: $neutral-90;
    }
  }
}
