.StakeModal {
  &__container {
    padding: 30px;
    min-width: 375px;
    width: 50%;
    overflow: visible;
  }

  &__loading {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, 0.3);
    z-index: 9999;
    height: 100%;
    width: 100%;
  }
}
