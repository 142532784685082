@import 'src/common/styles/colors';

.DataFeesSubscriptionsAlert {
  text-align: center;

  &__title {
    font-size: 1.1rem;
    font-weight: 500;
  }

  &__modal {
    padding: 40px;
    padding-bottom: 30px;
  }

  &__description {
    margin-top: 30px;
    margin-bottom: 40px;
    font-size: 0.9rem;

    p {
      margin: 20px 0;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 10px;

    .Button--primary,
    .Button--secondary {
      width: 130px;
    }
  }
}
