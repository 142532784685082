@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.Filters {
  background-color: rgba(white, 0.2);
  border-radius: 6px;
  padding: 30px 20px;
  margin-bottom: 16px;
  --tw-bg-opacity: 0.5;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 10px;
    margin-bottom: 16px;
  }

  &__row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
    margin-top: 20px;

    @media #{$breakpoint-md-down} {
      gap: 20px;
      grid-template-columns: 1fr;
    }
  }

  &__option-panel {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__saved {
    margin-left: auto;
  }

  &__network {
    width: 400px;
  }

  &__title {
    color: white;
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 1.75rem;
    text-align: left;
  }
}


@media screen and (max-width: 610px) {
  .Filters__text-filters {
    flex-wrap: wrap;
  }
}
