@import '../../../../common/styles/colors';
@import '../../../../common/styles/variables';
@import '../../../../common/styles/mixins/transitions';

%container {
  margin: 0 auto;
  width: 100%;
  max-width: 1340px;
}

%title {
  font-size: 28px;
  font-weight: 700;
  text-align: center;
  max-width: 1000px;
  width: 100%;
  color: $neutral-100;
  margin: 0 auto;
  margin-bottom: 40px;
}

%description {
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #a7a7ae;
  max-width: 680px;
  margin: 0 auto;
  margin-bottom: 40px;

  @media #{$breakpoint-md-down} {
    margin-bottom: 30px;
  }
}

.AttackDetectorPage {
  margin-top: 30px;

  &__container {
    @extend %container;

    text-align: left;
  }

  &__loader {
    margin: 0 auto;
    margin-top: 100px;
  }
}

.AttackDetectorPage .Hero {
  position: relative;
  display: flex;
  gap: 30px;
  margin: 0 auto;
  text-align: left;
  max-width: 1300px;

  &__info {
    position: relative;
    flex: 1.2;
  }

  &__gradient-1,
  &__gradient-2,
  &__gradient-3 {
    position: absolute;
    pointer-events: none;
    z-index: 0;
  }

  &__gradient-1 {
    top: 50px;
    left: -150px;
    height: 1000px;
    width: 820px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(3, 69, 67, 0.35) 0%,
      rgba(3, 69, 67, 0) 100%
    );
    transform: rotate(50deg);
  }

  &__gradient-2 {
    top: -406px;
    right: -138px;
    height: 1000px;
    width: 820px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(3, 69, 67, 0.5) 0%,
      rgba(3, 69, 67, 0) 100%
    );

    @media #{$breakpoint-md-down} {
      display: none;
    }
  }

  &__gradient-3 {
    top: 0px;
    right: -138px;
    height: 1000px;
    width: 820px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(219, 219, 219, 0.03) 0%,
      rgba(3, 69, 67, 0) 100%
    );
  }

  &__image {
    position: relative;
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;

    img {
      margin-top: 40px;
      width: 400px;
      object-fit: contain;

      @media #{$breakpoint-lg-down} {
        margin-top: 40px;
        margin-right: 0px;
      }

      @media #{$breakpoint-md-down} {
        width: 400px;
      }
    }

    @media #{$breakpoint-md-down} {
      justify-content: center;
    }
  }

  &__title {
    font-size: 40px;
    line-height: 120%;
    margin-bottom: 25px;
    font-weight: 700;

    @media #{$breakpoint-lg-down} {
      font-size: 32px;
    }

    @media #{$breakpoint-md-down} {
      font-size: 32px;
      line-height: 125%;
    }
  }

  &__description {
    font-size: 18px;
    line-height: 160%;
    margin-bottom: 33px;
    color: $neutral-60;

    @media #{$breakpoint-lg-down} {
      font-size: 18px;
    }
  }

  &__price {
    margin-bottom: 40px;

    &-value {
      font-size: 24px;
      font-weight: 700;
    }

    &-period {
      font-size: 24px;
      font-weight: 400;
      opacity: 0.3;
      margin-left: 10px;
    }
  }

  &__actions {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    @media #{$breakpoint-sm-down} {
      flex-direction: column;

      > * {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &__buy-button {
    max-width: 240px;
    width: 100%;
  }

  &__trial-button {
    max-width: 200px;
    width: 100%;
  }

  .BotLink {
    @include transition-fast();

    display: inline-flex;
    align-items: center;
    gap: 15px;
    padding: 12px 15px;
    margin-top: -15px;
    margin-bottom: 20px;
    border-radius: 10px;
    line-height: 100%;
    background-color: rgba(255, 255, 255, 0.1);

    &__link {
      display: flex;
      align-items: center;
      gap: 10px;
      color: #acacac;
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.15);
    }

    &:active {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }

  @media screen {
  }

  @media #{$breakpoint-md-down} {
    flex-direction: column;
  }
}

.AttackDetectorPage .MaintenanceInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 20px;
  gap: 18px;
  color: #c7c7c7;
  margin-top: 130px;

  &__partner {
    img {
      object-fit: cover;
      height: 32px;
    }
  }
}

.AttackDetectorPage .Highlights {
  margin-top: 180px;

  &__title {
    @extend %title;
  }

  &__description {
    @extend %description;

    margin-bottom: 40px;
  }

  &__networks {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 120px;

    @media #{$breakpoint-md-down} {
      gap: 20px;
      padding: 0 20px;
      margin-bottom: 80px;
    }
  }

  &__network {
    width: 32px;
    height: 32px;
  }

  &__metrics {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 860px;
    margin: 0 auto;

    &-separator {
      width: 4px;
      height: 80px;
      border-radius: 3px;
      background: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 0)
      );

      @media #{$breakpoint-md-down} {
        width: 160px;
        height: 4px;
        background: linear-gradient(
          -90deg,
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0)
        );
      }
    }

    @media #{$breakpoint-md-down} {
      flex-direction: column;
      gap: 30px;
    }
  }

  .KeyMetric {
    text-align: center;

    &__value {
      display: flex;
      justify-content: center;
      font-size: 46px;
      font-weight: 700;
      color: $neutral-100;
    }

    &__label {
      font-size: 18px;
      font-weight: 400;
      color: #ababab;


      @media #{$breakpoint-md-down} {
        font-size: 18px;
      }
    }
  }

  @media #{$breakpoint-md-down} {
    margin-top: 160px;
  }
}

.AttackDetectorPage .HowItWorks {
  margin-top: 200px;
  position: relative;

  &__title {
    @extend %title;
  }

  &__description {
    @extend %description;

    max-width: 660px;
  }

  &__lifecycle-image {
    display: block;
    margin: 0 auto;
    margin-top: 120px;
    margin-bottom: 120px;
    justify-content: center;

    @media #{$breakpoint-sm-down} {
      display: none;
    }
  }

  &__lifecycle-compact-image {
    display: none;
    margin-left: 40px;
    margin-top: 100px;
    margin-bottom: 100px;
    justify-content: center;

    @media #{$breakpoint-sm-down} {
      display: flex;
    }
  }

  &__description-2 {
    @extend %description;

    max-width: 880px;
    margin-bottom: 100px;
  }

  &__features {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 60px;
    max-width: 1260px;
    margin: 0 auto;

    > * {
      flex: 1;
    }

    @media #{$breakpoint-md-down} {
      flex-direction: column;
      gap: 30px;
    }
  }

  .FeatureCard {
    @include transition-fast();

    padding: 30px;
    background: #020303;
    border-radius: 12px;
    border: 2px solid rgba(255, 255, 255, 0.15);

    &__title {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 25px;

      @media #{$breakpoint-md-down} {
        font-size: 24px;
      }
    }

    &__description {
      font-size: 18px;
      line-height: 140%;
      color: #c7c7c7;
      min-height: 120px;
      margin-bottom: 25px;

      @media #{$breakpoint-md-down} {
        font-size: 18px;
      }
    }

    &__integrations {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
    }

    &__integration {
      img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
      }
    }

    &__link {
      display: block;
      margin-top: 50px;
      font-size: 20px;
      font-weight: 500;

      @media #{$breakpoint-md-down} {
        font-size: 18px;
      }
    }

    &:hover {
      background: rgb(6, 7, 7);
      border-color: rgba(255, 255, 255, 0.3);
    }
  }

  @media #{$breakpoint-md-down} {
    margin-top: 140px;
  }

  @media #{$breakpoint-sm-down} {
    margin-top: 130px;
  }
}

.AttackDetectorPage .Metrics {
  %block {
    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.15);
    background: rgba(0, 0, 0, 0.5);
  }

  position: relative;
  margin-top: 180px;

  &__gradient-1 {
    position: absolute;
    top: -200px;
    left: -400px;
    height: 1000px;
    width: 1000px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(3, 69, 67, 0.35) 0%,
      rgba(3, 69, 67, 0) 100%
    );
    pointer-events: none;
    transform: rotate(-45deg);
  }

  &__gradient-2 {
    position: absolute;
    top: -200px;
    right: -400px;
    height: 1000px;
    width: 1000px;
    transform: rotate(-45deg);
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgb(0 55 90 / 35%) 0%,
      rgba(3, 69, 67, 0) 100%
    );
    pointer-events: none;
  }

  &__title {
    @extend %title;
  }

  &__data {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;

    > * {
      flex: 1;
    }

    @media #{$breakpoint-md-down} {
      flex-direction: column;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    height: 100%;

    @media #{$breakpoint-md-down} {
      grid-template-columns: 1fr 1fr;
      gap: 20px;
    }
  }

  .Metric {
    @extend %block;

    position: relative;
    padding: 20px;

    &__label {
      font-size: 16px;
      font-weight: 400;
      color: #b7b7b7;

      @media #{$breakpoint-md-down} {
        font-size: 14px;
      }
    }

    &__value {
      font-size: 32px;
      font-weight: 500;
      line-height: 100%;
      margin-top: 10px;

      @media #{$breakpoint-md-down} {
        margin-top: 8px;
      }
    }

    @media #{$breakpoint-md-down} {
      padding: 15px;
    }
  }

  .NetworkChart {
    @extend %block;

    padding: 30px 40px;

    @media #{$breakpoint-md-down} {
      padding: 20px 30px;
    }

    &__title {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 10px;
    }

    &__description {
      font-size: 16px;
      line-height: 140%;
      color: #c7c7c7;
      margin-bottom: 30px;
    }

    &__data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 40px;

      @media #{$breakpoint-md-down} {
        grid-template-columns: 1fr;
        gap: 60px;
      }
    }

    &__list {
      display: flex;
      flex-direction: column;
      width: 240px;

      @media #{$breakpoint-md-down} {
        width: 100%;
      }
    }

    &__pie {
      min-height: 300px;
      margin-top: -40px;
    }

    .NetworkChartItem {
      display: flex;
      align-items: center;
      font-size: 16px;
      gap: 20px;
      padding: 10px 15px;
      margin: 0 -15px;
      border-radius: 4px;
      color: #c2c2c2;

      &__icon {
        width: 16px;
        height: 16px;
        border-radius: 100%;
      }

      &__name {
      }

      &__value {
        font-weight: 500;
        margin-left: auto;
      }

      &:hover {
        color: $neutral-100;
        background-color: rgba(255, 255, 255, 0.05);
      }
    }
  }

  @media #{$breakpoint-md-down} {
    margin-top: 140px;
  }
}

.AttackDetectorPage .Exploits {
  position: relative;
  margin-top: 200px;

  &__gradient-1 {
    position: absolute;
    top: -200px;
    left: -400px;
    height: 1000px;
    width: 1000px;
    transform: rotate(-45deg);
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(0, 57, 92, 0.25) 0%,
      rgba(3, 69, 67, 0) 100%
    );
    pointer-events: none;
  }

  &__gradient-2 {
    position: absolute;
    top: 66px;
    right: -491px;
    height: 1000px;
    width: 700px;
    transform: rotate(45deg);
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(130, 72, 229, 0.1) 0%,
      rgba(3, 69, 67, 0) 100%
    );
    pointer-events: none;
  }

  &__title {
    @extend %title;

    margin-bottom: 60px;
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 40px;

    @media only screen and (max-width: 1360px) {
      grid-template-columns: 1fr 1fr;
    }

    @media #{$breakpoint-sm-down} {
      grid-template-columns: 1fr;
    }
  }

  .ExploitCard {
    @include transition-fast();

    position: relative;
    display: flex;
    flex-direction: column;
    padding: 30px;
    height: 100%;
    background-color: rgba(30, 30, 30, 0.42);
    border-radius: 12px;
    border: 2px solid transparent;


    @media #{$breakpoint-md-down} {
      padding: 20px;
    }

    &__project {
      display: flex;
      gap: 15px;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 24px;
      color: #d9d9d9;
    }

    &__logo {
      width: 28px;
      height: 28px;

      > * {
        object-fit: contain;
        border-radius: 50%;
      }

      @media #{$breakpoint-md-down} {
        width: 26px;
        height: 26px;
      }
    }

    &__value {
      font-size: 32px;
      font-weight: 700;
      margin-bottom: 25px;

      @media #{$breakpoint-md-down} {
        font-size: 34px;
        margin-bottom: 24px;
      }
    }

    &__description {
      font-size: 16px;
      line-height: 140%;
      margin-bottom: 35px;
      color: #b5b5b5;
    }

    &__meta {
      margin-top: auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
      color: $neutral-50;
    }

    &:hover {
      background-color: rgba(51, 51, 51, 0.42);
      //border-color: rgba(255, 255, 255, 0.44);
      border-color: $blue;
    }

    @media #{$breakpoint-md-down} {
      padding: 20px;
    }
  }

  @media #{$breakpoint-md-down} {
    margin-top: 160px;
  }
}

.AttackDetectorPage .FinalCta {
  position: relative;
  margin-top: 140px;
  padding: 80px 0;

  @media #{$breakpoint-md-down} {
    margin-top: 100px;
  }

  &__title {
    @extend %title;

    margin-bottom: 30px;
    max-width: 740px;
  }

  &__description {
    @extend %description;

    font-size: 20px;
    max-width: 680px;
    margin-bottom: 40px;
  }

  &__price {
    text-align: center;
    margin-bottom: 50px;
    font-size: 24px;

    &-value {
      font-weight: 700;
    }

    &-period {
      font-weight: 400;
      opacity: 0.3;
      margin-left: 10px;
    }
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 25px;
    margin-bottom: 25px;

    @media #{$breakpoint-sm-down} {
      flex-direction: column;

      > * {
        width: 100% !important;
        max-width: 100% !important;
      }
    }
  }

  &__buy-button {
    max-width: 240px;
    width: 100%;
  }

  &__image {
    position: absolute;
    bottom: 0;
    pointer-events: none;
    z-index: 0;
  }
}

.AttackDetectorPage .ContactUs {
  padding: 80px 0;
  padding-bottom: 0;
  margin-top: 30px;

  &__title {
    @extend %title;
  }

  &__description {
    @extend %description;

    max-width: 620px;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 35px;
  }
}
