@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';

.NetworkPage {
  color: $lightgray;

  &__header {
    text-align: left;
    margin-top: 90px;
    margin-bottom: 60px;
  }

  &__title {
    @include h1-responsive;

    margin-bottom: 40px;
  }

  &__description {
    @include lead-lg();

    color: $gray;
    max-width: 879px;
  }

  @media #{$breakpoint-lg-down} {
    &__header {
      margin-top: 50px;
      margin-bottom: 40px;
    }
  }

  @media #{$breakpoint-md-down} {
    &__header {
      margin-top: 20px;
    }

    &__title {
      margin-bottom: 20px;
    }
  }

  @media screen and (max-width: 410px) {
    .InfoPopover {
      display: none;
    }
  }
}

.ChainList,
.ScannerList {
  &__title {
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 10px;
  }

  &__list {
    background-color: $darkgray;
    border-radius: 8px;
    border: 1px solid $gray-2;
    position: relative;
    min-height: 100px;

    > .Loader {
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(black, 0.6);

      .Loader__image {
        top: 20px;
        position: absolute;
      }
    }

    &-header {
      .ScannerList__item__id {
        font-family: inherit;
      }
    }
  }

  &__item {
    display: flex;
    text-align: left;
    padding: 12px 20px;
    border-bottom: 1px solid $gray-2;

    &__name {
      flex: 9 0 0%;
      display: flex;
    }

    &__scanners,
    &__agents {
      flex: 2 0 0%;
      text-align: right;
      min-width: 150px;
    }

    &__agents {
      flex: 2 0 0%;
      text-align: right;
    }

    &__capacity {
      flex: 2 0 0%;
      text-align: right;
      min-width: 143px;
    }

    &__uptime {
      flex: 2 0 0%;
      text-align: right;
      min-width: 100px;
    }

    &__number {
      width: 10px;
    }

    &__label {
      color: $blue;
    }

    &__icon {
      margin-left: 15px;
      margin-right: 15px;
    }

    &--disabled {
      color: $gray;
    }
  }
}

.ScannerList__item {
  &__id {
    flex: 7 0 0%;
    display: flex;
    font-family: 'Courier New', Courier, monospace;

    .Loader {
      width: 20px;
      margin-left: 10px;
    }
  }

  &__status,
  &__agents,
  &__capacity {
    flex: 2 0 0%;
    text-align: right;
  }

  &__date {
    flex: 2 0 0%;
    text-align: right;
  }

  &__agents {
    flex: 2 0 0%;
    text-align: right;
  }

  &__block {
    flex: 2 0 0%;
    text-align: right;
  }

  &__uptime {
    flex: 1 0 0%;
    text-align: right;
  }

  .enabled-status,
  .disabled-status {
    padding: 4px 6px;
    border-radius: 4px;
    color: $gray;
  }

  .enabled-status {
    color: #10b889;
  }
}

.ScannerList__item__id__hash-short {
  display: none;
}

.ButtonGroupMenu {
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
  row-gap: 10px;
}

.ButtonGroupMenu__item {
  display: inline-block;
  text-align: center;
  padding: 8px 12px;
  font-size: 0.825rem;
  font-weight: 600;
  cursor: pointer;
  border: 1px solid transparent;
  outline: none;
  background-color: rgba(255, 255, 255, 0.08);
  color: rgba(255, 255, 255, 0.64);
  min-width: fit-content;
  border-radius: 12px;
  white-space: nowrap;
  transition: all 0.2s ease-in;

  &:hover {
    background-color: rgba(255, 255, 255, 0.16);
  }

  &__selected,
  &__selected:hover {
    background-color: rgba(255, 255, 255, 0.32);
    color: rgba(255, 255, 255, 0.88);
  }
}

@media screen and (max-width: 900px) {
  .ChainList__item__capacity {
    display: none;
  }
}

@media screen and (max-width: 965px) {
  .ScannerList__item__capacity {
    display: none;
  }
}

@media screen and (max-width: 650px) {
  .ChainList__item__uptime {
    display: none;
  }
}

@media screen and (max-width: 590px) {
  .ScannerList__item__block {
    display: none;
  }
  .ChainList__item__scanners {
    display: none;
  }
}

@media screen and (max-width: 800px) {
  .ScannerList__item {
    .ScannerList__item__id {
      flex: 3 0 0%;
    }
    .ScannerList__item__id__hash {
      display: none;
    }
    .ScannerList__item__id__hash-short {
      display: block;
    }
  }
}

@media screen and (max-width: 410px) {
  .ScannerList__item__uptime {
    display: none;
  }

  .NetworkPage {
    .InfoPopover {
      display: none;
    }
  }
}
