@import "../../../../common/styles/colors";
@import "../../../../common/styles/variables";

.VaultSection {
  text-align: left;
  padding: 50px 40px;
  background-color: $gray-4;
  border-radius: 12px;

  &__container {
    width: 100%;
    position: relative;
  }

  &__header {
    padding-right: 200px;

    @media #{$breakpoint-md-down} {
      padding-right: 0;
    }
  }

  &__title {
    font-size: 32px;
    font-weight: 700;
    margin-bottom: 30px;
    line-height: 100%;
  }

  &__description {
    font-size: 16px;
    line-height: 160%;
    color: $neutral-50;
    max-width: 660px;

    @media #{$breakpoint-md-down} {
      max-width: 100%;
    }
  }

  &__stats {
    display: flex;
    gap: 30px;
    margin-top: 40px;

    @media #{$breakpoint-sm-down} {
      flex-direction: column;
    }
  }

  .VaultStat {
    padding: 20px;
    background-color: rgb(255 255 255 / 5%);
    border-radius: 8px;
    width: 100%;
    border: 1px solid transparent;

    &__label {
      font-size: 16px;
      color: $neutral-40;
    }

    &__value {
      display: flex;
      flex-wrap: wrap;
      gap: 0.3em;
      font-size: 24px;
      font-weight: 700;
    }

    &--highlighted {
      border-color: rgba(255, 255, 255, 0.35);
    }
  }

  &__deposit-button {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 200px;
    width: 100%;

    @media #{$breakpoint-md-down} {
      margin-top: 60px;
      position: static;
      width: 100%;
      max-width: 100%;
    }
  }
}
