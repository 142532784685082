@use 'sass:color';

@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.TableRow {
  $module: &;

  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  color: $neutral-70;
  border-bottom: 1px solid rgba(255, 255, 255, 0.04);

  &:last-child {
    border-bottom: none;
  }

  &--default {
    &:nth-child(2n):not(#{$module}--highlighted) {
      background: #212121;
    }

    &:hover:not(#{$module}--highlighted) {
      background: color.scale($gray-2, $lightness: 4%);
    }

    &#{$module}--highlighted {
      @include transition-medium();

      background-color: color.scale($gray-2, $lightness: 7%);
      border-bottom-color: rgba(184, 196, 255, 0.17);
    }
  }

  &--transparent {
    background-color: transparent;
    border-bottom: 1px solid rgba(255, 255, 255, 0.15);

    &:hover:not(#{$module}--highlighted) {
      background-color: #1d1d1d;
    }

    &#{$module}--highlighted {
      background-color: #1f1f1f;
    }
  }
}
