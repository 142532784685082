@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.ChipWrapper {
  display: inline-block;
  position: relative;

  &__remove-button {
    @include transition-fast();

    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    background: transparent;
    border-radius: 50%;
    transform: translateY(-50%);
    right: 6px;
    top: 50%;

    &:hover {
      background: rgba(255, 255, 255, 0.1);
    }

    &:active {
      background: rgba(255, 255, 255, 0.15);
    }
  }
}

.Chip {
  $module: &;

  @include transition-medium();

  font-size: 15px;
  line-height: 100%;
  font-weight: 600;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 8px;
  border-radius: 10px;

  &__icon {
    width: 20px;
    height: 20px;
  }

  &--default {
    color: $neutral-50;
    background-color: $neutral-15;

    &:hover {
      background-color: lighten($neutral-15, 5%);
    }

    &:active,
    &#{$module}--active {
      color: $neutral-70;
      background-color: $neutral-30;
    }
  }

  &--outline {
    color: $neutral-50;
    border: 1px solid $neutral-30;

    &:hover {
      border-color: $neutral-100;
    }

    &:active,
    &#{$module}--active {
      color: $black;
      border-color: $neutral-100;
      background-color: $neutral-100;
    }
  }

  &--sm {
    padding: 10px 12px;
  }

  &--md {
    padding: 13px 14px;
  }

  &--removable {
    padding-right: 40px;
  }
}

.ChipContainer {
  display: flex;
  gap: 10px;

  > * {
    flex-shrink: 0;
  }

  &--row {
    flex-direction: row;
    overflow-x: auto;
  }

  &--column {
    flex-direction: column;
  }
}
