@import 'src/common/styles/colors';

.BotMenuCell {
  &__modal {
    padding-top: 40px;
    padding-bottom: 70px;
    max-width: 900px;
    width: 100%;

    .Modal__children {
      color: white;
      text-align: left;
    }
  }
}
