@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.LineChart {
  position: relative;
  font-family: $font-display;

  &__empty-state {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(25, 24, 36, 0.2);
    text-align: center;
    backdrop-filter: blur(3px);
  }

  &__notification {
    text-align: center;
    font-size: 18px;
    color: $neutral-50;
    margin: -60px auto 0;
    max-width: 400px;
    padding-top: 0;
    padding-left: 40px;
    padding-right: 20px;
  }

  &__loader {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(25, 24, 36, 0.1);
    backdrop-filter: blur(1px);

    &-spinner {
      // make loader look better
      margin-top: -74px;
    }
  }
}
