@import 'src/common/styles/colors';

.ProjectPage {
  padding: 0 50px;
  color: white;

  a {
    transition: all 0.2s ease-out;
  }

  a:hover {
    opacity: 0.6;
  }

  &__go-back {
    text-align: left;
    margin-bottom: 30px;
    margin-top: 10px;

    &-button {
      display: inline-flex;
      align-items: center;
      grid-column-gap: 5px;
      column-gap: 5px;
      font-weight: normal;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__info {
    display: flex;
    align-items: center;
    column-gap: 20px;
  }

  &__logo {
    img {
      width: 128px;
    }
  }

  &__description {
    text-align: left;
  }

  &__name {
    font-size: 2.5em;
  }

  &__website {
    font-size: 0.8em;
    padding-left: 0.3em;

    a:hover {
      text-decoration: underline;
    }
  }

  &__stats {
    min-width: 150px;
    text-align: right;
  }

  &__agent-stats {
    font-size: 1.2em;
    display: flex;
    align-items: baseline;
    column-gap: 10px;
    justify-content: flex-end;

    .Counter {
      font-size: 2.5rem;
    }
  }

  &__see-alerts-button {
    background-color: $purple;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 0.8em;
  }

  &__agents {
    margin-top: 40px;
  }

  &__agents-title {
    font-size: 1.4em;
    text-align: left;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: baseline;
    margin-bottom: 10px;
  }

  &__agents-list {
  }

  &__agent {
    display: flex;
    flex-direction: column;
    padding: 15px;
    background-color: rgba(39, 39, 42, 0.5);
    border-radius: 10px;
    min-height: 135px;
    justify-content: space-between;
    margin-bottom: 20px;
  }

  &__agent-header {
    text-align: left;
  }

  &__agent-name {
    display: flex;
    font-size: 1.2rem;
    column-gap: 10px;
    justify-content: space-between;
  }

  &__agent-timeframe {
    font-size: 0.9rem;
    color: $gray;
    font-style: italic;
  }

  &__agent-developer {
    color: white;
    font-size: 0.8em;

    a {
      color: hsl(240deg 2% 50%);
    }
  }

  &__agent-content {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding-top: 20px;

    .Loader {
      width: 32px;
    }
  }

  &__agent-date {
    font-size: 0.8rem;
    color: $lightgray-2;
    display: flex;
    column-gap: 20px;
  }

  &__agent-date-value {
    color: $lightgray;
  }

  &__agent-metrics {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }

  &__agent-metric-labels {
    display: flex;
    padding-bottom: 5px;
    align-items: center;
    column-gap: 15px;
  }

  &__agent-metric-values {
    display: flex;
    align-items: center;
    column-gap: 15px;
  }

  &__agent-metric {
    width: 90px;
    font-size: 0.8rem;

    &-value {
      font-size: 1.2rem;
      width: 90px;
    }
  }

  &__agent-findings {
    color: $gray;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    a:nth-child(1).highlighted {
      color: $yellow;
    }
    a:nth-child(2).highlighted {
      color: $red;
    }
  }

  &__agent-description {
    padding-top: 5px;
    max-width: 40%;
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 813px) {
  .ProjectPage {
    padding: 0;
  }

  .ProjectPage__agent {
    width: 100%;
  }
}
