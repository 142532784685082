@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.overflow-x-hidden {
  overflow-x: hidden;
}

.GetStartedPage {
  $module: &;

  position: relative;
  font-family: $font-display;

  &__big-plan-list {
    position: relative;
    max-width: 1260px;
    margin-bottom: 60px;
  }

  &__plan-list {
    position: relative;
    max-width: 1260px;

    #{$module}__gradient-1 {
      content: '';
      position: absolute;
      right: -10%;
      top: -20%;
      width: 1167px;
      height: 882px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 229, 215, 0.24) 0%,
        rgba(0, 229, 215, 0) 100%
      );
      animation: 5s ease-in-out 0s infinite alternate gradient-1-animation;
      pointer-events: none;

      @media #{$breakpoint-md-down} {
        top: 0%;
      }

      @keyframes gradient-1-animation {
        0% {
          transform: translate(0, 0);
          opacity: 1;
        }
        50% {
          opacity: 0.8;
          transform: translate(-6%, -100px);
        }
        100% {
          transform: translate(-20%, 40px);
          opacity: 1;
        }
      }
    }

    #{$module}__gradient-2 {
      content: '';
      position: absolute;
      left: -20%;
      top: -4%;
      width: 1138px;
      height: 1266px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 138, 206, 0.26) 0%,
        rgba(0, 138, 206, 0) 100%
      );
      animation: 4s ease-in-out 0s infinite alternate gradient-2-animation;
      animation-delay: 5s;
      pointer-events: none;

      @media #{$breakpoint-md-down} {
        top: 20%;
      }

      @keyframes gradient-2-animation {
        0% {
          transform: translate(0, 0);
        }
        50% {
          opacity: 0.8;
          transform: translate(-20%, -100px);
        }
        100% {
          transform: translate(-10%, -100px);
          opacity: 1;
        }
      }
    }

    #{$module}__gradient-3 {
      content: '';
      position: absolute;
      right: -15%;
      bottom: -20%;
      width: 1100px;
      height: 621px;
      background: radial-gradient(
        50% 50% at 50% 50%,
        rgba(0, 229, 215, 0.12) 0%,
        rgba(0, 229, 215, 0) 100%
      );
      pointer-events: none;

      @media #{$breakpoint-md-down} {
        top: 70%;
      }
    }

    #{$module}__line {
      position: absolute;
      left: -6px;
      width: 100%;
      top: 105%;

      @media only screen and (max-width: 1340px) {
        display: none;
        top: 67%;
      }

      @media #{$breakpoint-md-down} {
        top: 80%;
      }
    }

    #{$module}__cards {
      position: relative;

      #{$module}__card {
        @media only screen and (max-width: 1340px) {
          height: 460px;
        }

        @media #{$breakpoint-md-down} {
          height: unset;
        }
      }
    }
  }

  &__actions {
    position: relative;
    margin-top: 60px;
  }

  &__link {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    text-decoration-line: underline;

    color: #e6e7ed;
    margin-bottom: 42px;
  }

  &__button-with-link {
    display: flex;
    flex-direction: column;
  }

  &__link_under_button {
    font-weight: 700;
    font-size: 12px;
    text-align: center;
    text-decoration-line: underline;
    float: right;
    align-self: end;
    color: #e6e7ed;
    margin-top: 2px;
  }

  &__SC-gradient {
    position: absolute;
    width: 1186px;
    height: 959px;
    left: -52%;
    top: 0px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      $accent-blue-40 0%,
      rgba(15, 15, 15, 0) 100%
    );
  }

  &__wallet-gradient {
    position: absolute;
    width: 1186px;
    height: 959px;
    left: 71%;
    top: 0px;
    background: radial-gradient(
      50% 50% at 50% 50%,
      rgba(48, 245, 141, 0.57) 0%,
      rgba(15, 15, 15, 0) 100%
    );
  }

  &__monitor {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__progress {
    display: flex;
    justify-content: space-between;
    width: 175px;
    margin-bottom: 17px;

    &-bar {
      height: 2px;
      width: 54px;
      background: rgba(255, 255, 255, 0.2);

      &--active {
        @extend .GetStartedPage__progress-bar;
        background: white;
      }
    }
  }

  &__signin-message {
    text-align: center;
    margin-top: 30px;
    font-size: 1.2em;
    color: $lightgray-2;
    border: 1px solid $gray;
    padding: 100px;
  }

  &__alignB {
    display: flex;
    align-items: flex-end;
    height: 100%;
  }

  &__how {
    font-weight: 700;
    font-size: 16px;
    line-height: 23px;
    text-align: center;
    text-decoration-line: underline;
    color: #e6e7ed;
    margin-bottom: 42px;
  }

  &__monitorWrapper {
    background: linear-gradient(#0f0f0f, #0f0f0f) padding-box,
      linear-gradient(121deg, #ff2424 -13.38%, rgba(255, 255, 255, 0.19) 91.48%)
        border-box;
    border: 1px solid transparent;
    border-radius: 64px;
  }

  &__monitorBlock {
    position: relative;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    border: 1px solid rgba(255, 255, 255, 0.3);
    align-items: center;
    margin-top: 100px;
    backdrop-filter: blur(50px);
    padding-bottom: 29px;
    padding-left: 15px;
    padding-right: 15px;

    &-backButton {
      width: 50px;
      height: 50px;
      position: absolute;
      color: white;
      left: 15px;
      top: 15px;
    }

    &-finalCTA {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      color: white;
      width: 855px;
      padding-bottom: 6px;
      padding-left: 14.5px;
      padding-right: 14.5px;

      &-line {
        display: flex;
        align-items: flex-start;

        &-a {
          height: 115px;
          width: 1px;
          background: rgba(255, 255, 255, 0.1);
        }
      }

      &-item {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 10px;
        justify-content: space-between;
        width: 214px;
        height: 154px;

        &-text {
          color: #e6e7ed;
          font-size: 15px;
          line-height: 23px;
          padding: 14px;
        }

        &-clickMe {
          font-weight: 700;
          margin-bottom: 33px;

          a {
            display: flex;
            align-items: center;
            column-gap: 10px;

            div {
              color: #0a0a0a;
            }
          }
        }
      }
    }

    &-icon {
      display: flex;
      justify-content: center;
      margin-top: 48px;
    }

    &-headerWrapper {
      display: flex;
      width: 100%;
      justify-content: center;
    }

    &-header {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
    }

    &-align {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-alignConf {
      @extend .GetStartedPage__monitorBlock-align;
      align-items: flex-start;
      margin-bottom: 47px;
    }

    &-title {
      position: relative;
      font-style: normal;
      font-size: 48px;
      font-weight: 400;
      text-align: center;
      line-height: 54px;
      color: white;
      margin-top: 40px;
      z-index: 1;

      &--bold {
        font-weight: 700;
      }
    }

    &-subtitle {
      position: relative;
      margin-top: 16px;
      margin-bottom: 60px;
      max-width: 420px;
      font-weight: 400;
      line-height: 24px;
      color: #8fa3a3;
      z-index: 1;
    }

    &-positionText {
      position: relative;
      left: -25px;
    }

    &-h2 {
      font-family: 'neue-haas-display';
      font-style: normal;
      font-weight: 400;
      font-size: 40px;
      line-height: 50px;
      text-align: center;
      text-transform: capitalize;
      color: white;
      margin-top: 24px;
    }

    &-h3 {
      @extend .GetStartedPage__monitorBlock-h2;
      margin-top: 33px;
      width: 634px;
      height: 100px;
      text-transform: none;
      font-size: 40px;
      line-height: 50px;

      .SC {
        font-weight: 700;
        color: $accent-blue-40;
      }
      .wallet {
        font-weight: 700;
        color: $accent-green-40;
      }
    }
  }

  &__CTA {
    display: flex;
    flex-direction: row;
    justify-content: center;
    column-gap: 16px;

    &-column {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 393px;
      border: 1px solid rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(50px);
      border-radius: 10px;
      padding-bottom: 10px;

      &-line {
        background-color: #434343;
        border-color: #434343;
        width: 313.8px;
        height: 2px;
        margin: 2px 0 12.68px;
      }

      &-titleLHS {
        font-family: 'neue-haas-display';
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 50px;
        color: $accent-blue-40;
        padding-top: 4px;
      }

      &-textLHS {
        font-family: 'neuehaas';
        font-style: normal;
        font-size: 17px;
        text-align: left;
        line-height: 23px;
        position: relative;
        max-width: 305px;

        ul {
          list-style-type: disc;
          margin-left: 27px;
          padding-left: 0;
          margin-bottom: 43px;
        }

        &--bold {
          font-weight: 900;
          font-size: 17px;
        }

        a {
          text-decoration: underline;
        }
      }

      #{&}-buttonLHS {
        width: 340px;
        height: 60px;
        border: 4px solid $accent-blue-40;
        border-radius: 15px;
        background: transparent;
        font-family: 'neue-haas-display';
        font-style: normal;
        font-weight: 100;
        font-size: 28px;
        line-height: 50px;
        margin-bottom: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        column-gap: 7px;
        padding: 4px;

        &:hover {
          background: linear-gradient($accent-blue-40, $accent-blue-40)
              padding-box,
            linear-gradient($accent-blue-40, $accent-blue-40) border-box;
          color: $neutral-10;
        }

        &--bold {
          font-weight: 900;
        }
      }

      &-titleRHS {
        @extend .GetStartedPage__CTA-column-titleLHS;
        color: $accent-green-40;
      }

      &-textRHS {
        @extend .GetStartedPage__CTA-column-textLHS;

        &--bold {
          font-weight: 700;
          font-size: 17px;
        }
      }

      #{&}-buttonRHS {
        @extend .GetStartedPage__CTA-column-buttonLHS;
        border-color: $accent-green-40;

        &:hover {
          background: linear-gradient($accent-green-40, $accent-green-40)
              padding-box,
            linear-gradient($accent-green-40, $accent-green-40) border-box;
        }

        &--bold {
          font-weight: 700;
        }
      }

      button {
        --color1: #ff2424;
        --color2: #ff4c6b;
        --color3: #ff774c;

        font-size: 30px;
        line-height: 50px;
        height: 75px;
        width: 300px;
        border-radius: 66px;
        background: linear-gradient(
          87.45deg,
          var(--color1) 0%,
          var(--color2) 45.44%,
          var(--color3) 101.45%
        );
        font-weight: 700;
        margin-bottom: 17px;

        &:hover {
          --color1: rgba(255, 255, 255, 0);
          --color2: rgba(255, 255, 255, 0);
          --color3: rgba(255, 255, 255, 0);
          background: linear-gradient(#2b2b2b, #2b2b2b) padding-box,
            linear-gradient(
                87.45deg,
                #ff2424 0%,
                #ff4c6b 45.44%,
                #ff774c 101.45%
              )
              border-box;
          border: 4px solid transparent;
          transition: all 0.2s ease-out;
        }
      }
    }
  }

  &__boxWrapper {
    display: flex;
    width: 800px;
    padding: 24.59px 2.92px 24.37px 30.21px;
    border-radius: 10px;
    margin-top: 9px;
    border: 1px solid rgba(255, 255, 255, 0.3);
  }

  &__box {
    display: flex;
    justify-content: space-between;
    width: 881px;
    height: 46px;

    &-content {
      display: flex;
      column-gap: 17px;
      align-items: center;

      &-sideText {
        height: 40.04px;
        width: 191.5px;
      }

      &-middleText {
        height: 40.04px;
        width: 221.1px;
      }

      p {
        color: white;
        font-size: 14px;
        text-align: left;
        line-height: 23px;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }

  &__sub-title {
    position: relative;
    z-index: 1;
    font-size: 20px;
    margin-bottom: 50px;
    font-weight: 500;
    letter-spacing: 0.02em;
  }

  &__decoration-2 {
    position: absolute;
    z-index: 1;

    content: '';
    left: -20%;
    top: -200px;
    width: 900px;
    height: 600px;
    background: radial-gradient(
                    50% 50% at 50% 50%,
                    rgba(0, 229, 215, 0.24) 0%,
                    rgba(0, 229, 215, 0) 100%
    );
    pointer-events: none;
  }

  &__decoration-3 {
    position: absolute;
    z-index: 1;

    content: '';
    right: -20%;
    top: -200px;
    width: 600px;
    height: 600px;
    background: radial-gradient(
                    50% 50% at 50% 50%,
                    rgba(0, 138, 206, 0.26) 0%,
                    rgba(0, 138, 206, 0) 100%
    );
    pointer-events: none;
  }
}

.GettingStartedCTA {
  &__text {
    font-weight: 400;
    font-size: 25px;
    line-height: 50px;

    &-bold {
      @extend .GettingStartedCTA__text;
      font-weight: 700;
    }
  }

  &__form {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  &__submit-SCButton {
    width: 285px;
    height: 57px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    display: flex;
    justify-content: center;

    button {
      line-height: 20px;
      display: flex;
      width: 100%;
      color: white;
      border-radius: inherit;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 3px solid $accent-blue-40;
      }
    }
  }

  &__submit-walletButton {
    width: 285px;
    height: 57px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    border-radius: 20px;
    display: flex;
    justify-content: center;

    button {
      line-height: 20px;
      display: flex;
      width: 100%;
      color: white;
      border-radius: inherit;
      align-items: center;
      justify-content: center;

      &:hover {
        border: 3px solid $accent-green-40;
      }
    }
  }

  &__Input {
    textarea {
      height: 20px;
      overflow: hidden;
      resize: none;
      padding: 0px;
    }

    textarea::placeholder {
      font-family: 'neue-haas-display';
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 1px;
      padding-top: 1px;
      text-transform: capitalize;
      color: #e6e7ed;
    }
  }

  &__fill-wallet-btn {
    color: $gray;

    &:hover {
      color: $blue;
    }

    float: right;
    transition: color 0.2s ease-out;
    font-size: 0.9em;
  }

  &__form-address {
    padding: 0px 37px;
    width: 100%;
  }

  &__form-source {
    height: 100%;
  }

  &__form-source-value {
    height: 100%;

    .Input {
      font-size: 20px;
      background: none;
      border: 1px solid rgba(255, 255, 255, 0.3);
      padding: 20px 0px 20px 24px;
    }
  }

  &__form-SCEndpoint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 34px;

    .EndpointSelector {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        font-size: 18px;
        color: white;
        font-weight: 500;
        margin-bottom: 10px;
        padding-top: 0px;
      }
    }

    .EndpointSelector__type {
      width: 785px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      margin-bottom: 25.83px;
    }

    .EndpointSelector__input-text {
      background: none;
      padding: 0px;
      margin-bottom: 30px;

      .Input {
        border-radius: 10px;
        background: none;
        border: 1px solid rgba(255, 255, 255, 0.3);

        input::placeholder {
          color: white;
        }
      }
    }

    .EndpointSelector__type-item {
      height: 44px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: white;
      flex: none;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 10px;
      transition: background-color 0.2s ease-out;

      &--selected {
        @extend .EndpointSelector__type-item;
        border: 3px solid $accent-blue-40;
        background-color: transparent;
        padding: 7.5px 23.5px;
      }

      img {
        display: none;
      }
    }

    .EndpointSelector__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
    }
  }

  &__form-walletEndpoint {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin-top: 34px;

    .EndpointSelector {
      display: flex;
      flex-direction: column;
      align-items: center;

      &__title {
        font-size: 18px;
        color: white;
        font-weight: 700;
        margin-bottom: 23px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 0px;

        svg {
          display: inline;
          margin-left: 11px;
        }
      }
    }

    .EndpointSelector__type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      column-gap: 20px;
      margin-bottom: 25.83px;
    }

    .EndpointSelector__input-text {
      background: none;
      padding: 0px;
      margin-bottom: 30px;
    }

    .EndpointSelector__type-item {
      height: 44px;
      font-size: 20px;
      font-weight: 700;
      line-height: 24px;
      color: white;
      flex: none;
      border: 1px solid rgba(255, 255, 255, 0.3);
      border-radius: 10px;
      transition: background-color 0.2s ease-out;

      &--selected {
        @extend .EndpointSelector__type-item;
        border: 3px solid $accent-green-40;
        background-color: transparent;
        padding: 7.5px 23.5px;
      }

      img {
        display: none;
      }
    }

    .EndpointSelector__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: none;
    }
  }
}

.FortaFeatures {
  width: 100%;
  padding-top: 110px;

  &__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    letter-spacing: 0.02em;
    text-align: center;
    background: linear-gradient(
      87.45deg,
      $accent-green-50 100%,
      $accent-blue-50 100%
    );
    background-clip: text;
    color: transparent;
    margin-bottom: 48px;
  }

  &__list {
    position: relative;
    margin-top: 32px;
    padding: 64px 24px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-content: space-around;
    border-radius: 24px;
    gap: 30px;
    border: double 1px transparent;
    background-image: linear-gradient($black, $black),
      linear-gradient(to top, #00aaff66, #1cfdd799);
    background-origin: border-box;
    background-clip: padding-box, border-box;

    // Fix visual center
    padding-left: 12px;
    padding-right: 38px;

    @media #{$breakpoint-md-down} {
      grid-template-columns: 1fr 1fr;
      row-gap: 86px;
      padding: 48px 24px;
    }

    @media #{$breakpoint-sm-down} {
      grid-template-columns: 1fr;
      padding: 80px 24px;
    }
  }

  &__box {
    position: relative;
    margin: 0 auto;
    width: 100%;

    &-title {
      display: flex;
      justify-content: center;
      padding-bottom: 16px;
      font-weight: 700;
    }

    &-title-icon {
      padding-right: 8px;
    }

    &-content {
      color: $neutral-43;
      max-width: 190px;
      font-size: 14px;
      margin: 0 auto;

      &--long {
        max-width: 210px;
      }
    }

    &:after {
      position: absolute;
      content: '';
      right: -12px;
      top: 50%;
      transform: translateY(-50%);
      border-left: 1px solid;
      border-image: linear-gradient(
          rgba(26, 255, 217, 0.3),
          rgba(76, 210, 255, 0.3)
        )
        30;
      height: 108px;
    }

    &:last-child:after {
      display: none;
    }

    @media #{$breakpoint-md-down} {
      &:nth-child(2n):after {
        display: none;
      }
    }

    @media #{$breakpoint-sm-down} {
      &:not(:last-child):after {
        display: block !important;
        height: 1px;
        border-bottom: 1px solid;
        border-left: 0;
        width: 64px;
        transform: translateX(-50%);
        right: unset;
        top: unset;
        left: 50%;
        bottom: -40px;
      }
    }
  }
}

.BigPlanCard {
  $module: &;

  z-index: 1;
  position: relative;
  background: black;
  padding: 35px;
  border-radius: 24px;
  text-align: left;
  background: linear-gradient(
    141deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.5) 100%
  );
  width: 100%;
  backdrop-filter: blur(40px);
  transition: all 0.2s ease-out;
  border: 1px solid rgba(31, 253, 220, 0.2);

  &__icon {
    position: absolute;
    right: 35px;
    top: 35px;
  }

  &__title {
    color: #fff;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    font-family: $font-display;
    margin-bottom: 8px;
  }

  &__author {
    color: #646568;
    font-weight: 400;
    font-size: 14px;
    font-style: normal;
    line-height: normal;
    transition: all 0.2s ease-out;
    margin-bottom: 20px;

    &-value {
      color: #7e888c;
      font-weight: 500;
      margin-left: 2px;
      transition: all 0.2s ease-out;
    }
  }

  &__description {
    color: #8f9ca3;
    font-family: $font-text;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    max-width: 800px;
  }

  &__actions {
    display: flex;
    gap: 15px;
    margin-top: 20px;
  }

  &__price {
    font-size: 16px;
    color: white;
    font-weight: 700;
    margin-top: 20px;

    &-period {
      color: $neutral-35;
      font-weight: 400;
    }
  }

  &:hover {
    box-shadow: 0px 8px 24px 0px rgba(149, 253, 246, 0.2);

    #{$module}__author {
      color: #b3b3b7;

      &-value {
        color: #cacbce;
      }
    }

    #{$module}__description {
      color: #afb1b6;
    }
  }
}
