@import 'src/common/styles/colors';

.Dropdown-container--dark {
  .Dropdown__control {
    background-color: $gray-2;
    border: 1px solid $gray-2;
    transition: all 0.2s ease-out;

    &:hover {
      border: 1px solid $blue;
    }
  }

  .Dropdown__single-value {
    color: $lightgray;
  }

  .Dropdown__menu {
    color: $lightgray;
    background-color: $gray-2;
    transition: all 0.2s ease-out;

    .Dropdown__option {
      transition: all 0.2s ease-out;

      &--is-focused {
        background-color: $darkblue;
      }

      &:hover {
        background-color: $darkblue;
      }
    }
  }
}
