@import 'src/common/styles/colors';

.MyAgentsPage {
  $module: &;

  color: white;

  .PendingBotsTable {
    margin-top: 30px;

    &__header {
      padding-bottom: 10px;
    }
  }

  .plus {
    width: 1.5rem;
    height: 1.5rem;
  }

  &__header {
    text-align: left;
  }

  &__content {
    margin: 0 10px;
  }

  &__title {
    font-size: 2rem;
  }

  &__address {
    color: $lightgray-2;
  }

  &__list {
    text-align: left;
    border-radius: 6px;
    position: relative;
  }

  &__table {
    #{$module}__row {
      height: 70px;
    }
  }

  &__status-box {
    background-color: $gray;
    padding: 4px 6px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;

    &::before {
      content: '•';
      font-size: 30px;
      line-height: 17px;
      margin-right: 5px;
    }

    &--enabled {
      background-color: rgb(209, 250, 229);
      color: rgb(6, 95, 70);
    }

    &--failed {
      background-color: $red;

      &::before {
        display: none;
      }
    }

    &--pending {
      background-color: $magenta;

      &::before {
        display: none;
      }
    }
  }

  &__stake-box {
    font-size: 30px;
    padding: 5px;

    &--unstaked {
      color: $red;
    }

    &--staked {
      color: $green;
    }
  }

  &__list-content {
    background-color: $darkgray;
    border-radius: 4px;
  }

  &__list-header {
    display: flex;
    text-transform: uppercase;
    font-size: 0.75rem;
    padding: 20px 0;
    padding-left: 10px;
    margin: 0 15px;
  }

  &__list-header-name {
    flex: 9 0 0%;
  }

  &__list-header-stake {
    flex: 3 0 0%;
  }

  &__list-header-plan {
    flex: 3 0 0%;
  }

  &__list-header-date {
    flex: 3 0 0%;
  }

  &__list-header-status {
    flex: 2 0 0%;
    align-self: center;
  }

  &__list-header-edit {
    flex: 1 0 0%;
    align-self: center;
  }

  &__list-agents {
    padding: 15px;
    padding-top: 0;
    background-color: $gray-2;
    min-height: 100px;
  }

  &__agent {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    padding-left: 10px;
    align-items: center;
  }

  &__agent-name {
    flex: 9 0 0%;
    flex-direction: column;

    &-row {
      display: flex;
    }

    a {
      color: $lightgray-2;
      transition: all 0.2s ease-out;

      &:hover {
        color: white;
      }
    }

    .MyAgentsPage__agent-id {
      font-size: 0.9rem;
      color: $gray;
      word-break: break-word;
    }
  }

  &__agent-version {
    background-color: $neutral-30;
    padding: 0px 6px;
    border-radius: 4px;
    margin-left: 10px;
    font-size: 0.8rem;
    cursor: default;
    min-width: 30px;
    height: 22px;
    text-align: center;
  }

  &__agent-pending {
    background-color: $orange;
    padding: 2px 6px;
    border-radius: 4px;
    margin-left: 10px;
    font-size: 0.8rem;
    cursor: default;
  }

  &__agent-stake {
    display: flex;
    align-items: center;
    flex: 3 0 0%;

    &-value {
      display: flex;
      gap: 8px;
    }
  }

  &__agent-plan {
    flex: 3 0 0%;
    align-self: center;
  }

  &__agent-date {
    flex: 3 0 0%;
    overflow: hidden;
    text-overflow: ellipsis;

    & > div {
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 0.9rem;
    }
  }

  &__agent-status {
    flex: 2 0 0%;
    align-self: center;
  }

  &__deploy {
    display: flex;
    align-items: center;

    &-button {
      background-color: $purple;
      color: $lightgray;
      border-radius: 6px;
      display: flex;
      font-weight: 500;
      line-height: 1rem;
      vertical-align: middle;
      font-size: 0.875rem;
      padding: 0.25rem 0.75rem;
      text-align: center;
      transition: opacity 0.2s ease-out;
      opacity: 1;
      outline: none;

      &:hover {
        opacity: 0.8;
      }
    }

    &-button > div {
      display: flex;
      align-items: center;
    }
  }

  &__metadata {
    display: flex;
    margin-top: 50px;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__metrics {
    display: flex;
    column-gap: 15px;
  }

  &__metric {
    background-color: $gray-2;
    padding: 5px 10px;
    border-radius: 4px;
    width: 100px;
    height: 74px;

    &-value {
      font-size: 1.6rem;
      font-weight: bold;
    }
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    padding-top: 50px;
    overflow: hidden;
    background-color: rgba(black, 0.6);
    backdrop-filter: blur(1px);

    &-text {
      margin-top: 20px;
    }
  }

  &__signin-message {
    text-align: center;
    margin-top: 30px;
    font-size: 1.2em;
    color: $lightgray-2;
    border: 1px solid $gray;
    padding: 100px;
  }

  &__list-empty {
    text-align: center;
    padding-top: 35px;
    font-style: italic;
  }

  &__pending-updates {
    margin-top: 20px;

    &-title {
      font-size: 1.2rem;
      margin-bottom: 10px;
    }
  }

  &__update {
    display: inline-flex;
    column-gap: 30px;
    background-color: $gray-2;
    padding: 10px;
    border-radius: 4px;
    align-items: center;
  }

  &__bot-chain {
    display: flex;
    flex-wrap: wrap;

    a {
      width: auto;
    }

    &-icon {
      display: inline-block;
      width: 22px;

      &:not(:first-child) {
        margin-left: -8px;
      }

      img {
        border-radius: 50%;
      }
    }

    &-icons {
      display: flex;
      gap: 4px;
      margin-left: 12px;
      align-items: center;
    }
  }
}
