@import '../../../common/styles/colors';

.MutedFilter {
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
  column-gap: 20px;
  margin-top: 20px;

  &__alert {
    background-color: $gray-2;
    color: $lightgray-2;
    padding: 5px;
    font-size: 0.7em;
    display: inline-flex;
    border-radius: 3px;

    button {
      margin-left: 5px;
      transition: color 0.2s ease-in;

      &:hover {
        color: white;
      }
    }
  }

  &__alerts {
    display: inline-flex;
    flex-wrap: wrap;
    column-gap: 10px;
    row-gap: 10px;
  }

  &__title {
    color: white;
  }
}
