@import 'src/common/styles/colors';

.TableBody {
  position: relative;

  &--loading,
  &--empty {
    min-height: 170px;
  }

  &__notification {
    padding: 0 20px;
    padding-top: 60px;
    text-align: center;
    margin: 0 auto;
    max-width: 600px;

    &-message {
      color: $neutral-50;
      font-size: 16px;
      line-height: 150%;
    }
  }

  &--loading {
    // TableBody embeds Loader at the end of the list, so the last TableRow comes before the last
    .TableRow:nth-last-child(2) {
      border-bottom: none;
    }
  }
}
