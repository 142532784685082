// These variables are also duplicated in the useViewPort() hook.

$xs-max: 576px !default;
$sm-max: 768px !default;
$md-max: 1050px !default;
$lg-max: 1470px !default; // macbook air m2 resolution
$xl-max: 1920px !default;

$sm-start: ($xs-max + 1);
$md-start: ($sm-max + 1);
$lg-start: ($md-max + 1);
$xl-start: ($lg-max + 1);

$breakpoint-sm-down: 'only screen and (max-width: #{$sm-max})';
$breakpoint-md-down: 'only screen and (max-width: #{$md-max})';
$breakpoint-lg-down: 'only screen and (max-width: #{$lg-max})';
$breakpoint-xl-down: 'only screen and (max-width: #{$xl-max})';

$container-width: 1620px;

$z-index-header: 50;
$z-index-modal: 999;

$font-text: 'neuehaas';
$font-display: 'neue-haas-display';
