@import 'src/common/styles/colors';

.MuteFilter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px 15px;

  &__label {
    color: $lightgray;
  }

  &__alerts {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }

  &__alert {
    color: $lightgray-2;
    background-color: transparentize($black, 0.3);
    padding: 5px;
    font-size: 14px;
    display: inline-flex;
    border-radius: 3px;

    button {
      margin-left: 5px;
      transition: color 0.2s ease-in;

      &:hover {
        color: white;
      }
    }
  }
}
