@use 'sass:color';

@import 'common/styles/variables';
@import 'common/styles/colors';
@import 'common/styles/mixins/transitions';

.FileItem {
  $module: &;
  $bg-color: #161616;

  @include transition-medium();

  display: flex;
  align-items: center;
  // TODO Refactor colors?
  //background-color: $gray-4;
  background-color: $bg-color;
  border: 1px solid rgba(255, 255, 255, 0.03);
  padding: 12px 20px;
  border-radius: 6px;

  &:hover {
    background-color: color.scale($bg-color, $lightness: 1%);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }

  &__source {
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    max-width: 240px;

    #{$module}__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 60px;
      width: 60px;
      color: $neutral-45;
      background: rgba(255, 255, 255, 0.07);
      border-radius: 8px;
      margin-right: 10px;
    }

    #{$module}__file {
      width: 100%;
      max-width: 250px;
      overflow: hidden;

      &-name {
        font-size: 14px;
        font-weight: 500;
        color: $neutral-60;
        margin-bottom: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-subtitle {
        font-size: 12px;
        color: $neutral-40;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &__separator {
    display: block;
    width: 1px;
    height: 50px;
    background-color: $neutral-20;
    margin: 0 40px;
  }

  &__name-input {
    max-width: 340px;
    width: 100%;
  }

  &__tooltip {
    margin-left: 6px;
  }

  &__remove-button {
    margin-left: auto;
  }

  &--invalid {
    border-color: red !important;
  }
}
