@import 'src/common/styles/colors';
@import 'src/common/styles/variables';
@import 'src/common/styles/mixins/transitions';

.LabelTable {
  font-family: $font-text;
}

.LabelRow {
  $module: &;

  &__label-title {
    // the same as in the AlertRow
    color: rgb(197, 176, 176);
  }

  &__details-button {
    @include transition-fast();

    background-color: $neutral-25;
    padding: 8px 12px;
    width: 88px;
    height: auto;
  }

  &__details {
    margin-bottom: 20px;
  }

  &__metadata {
    padding: 0 24px;
  }

  &:hover {
    #{$module}__details-button {
      background-color: $neutral-30;

      &:active {
        background-color: $neutral-35;
      }
    }
  }
}