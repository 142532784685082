@import 'src/common/styles/colors';

.TableCell {
  display: flex;
  align-items: center;
  text-align: left;
  border-right: 1px solid rgba(255, 255, 255, 0.1);
  height: 100%;
  font-weight: 400;
  font-size: 16px;
  line-height: 23px;
  min-width: 0;

  &:last-child {
    border-right: none;
  }
}

.TableCheckableCell {
  flex: unset !important;
  justify-content: center;
  padding-left: 20px;
  border-right: 0;
}
