@import "src/common/styles/colors";
@import "src/common/styles/variables";

.Score {
  padding: 2px 4px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 6px;
  min-width: 70px;
  text-align: center;
  background: rgba(255, 255, 255, 0.07);


  &--yellow {
    color: #F4D240;
    background: rgba(237, 204, 63, 0.07);
  }

  &--green {
    color: #49BD84;
    background: rgba(73, 188, 131, 0.07);
  }
}
