@import 'src/common/styles/colors';

.NotificationEmailList {
  margin-top: 60px;

  &__title {
    font-size: 1em;
    color: white;
    text-align: left;
    margin-bottom: 20px;
  }

  &__email {
    &-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px;
      border-bottom: 1px solid rgba(white, 0.1);
      transition: all 0.2s ease-out;
      flex-wrap: wrap;

      &:hover {
        background-color: rgba(white, 0.03);
      }
    }

    &-content {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    &-value {
      font-size: 1em;
    }

    &-state {
      margin-left: 20px;
      font-size: 0.7em;
      color: $gray-2;
      background-color: $yellow;
      border-radius: 3px;
      padding: 4px 6px;

      &[data-verified='true'] {
        background-color: $green;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-between;
      column-gap: 10px;
      font-size: 0.8em;

      .Loader {
        width: 20px;
      }
    }
  }
}

@media screen and (max-width: 740px) {
  .NotificationEmailList__title {
    .InfoPopover {
      display: none;
    }
  }
}
