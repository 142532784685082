@mixin multiple-transition(
  $properties: (
    all
  ),
  $duration: 200ms,
  $method: ease,
  $delay: 0ms
) {
  $value: ();

  @each $property in $properties {
    @if length($value) > 0 {
      $value: append(
        $value,
        unquote(',') #{$property} $duration $method $delay
      );
    } @else {
      $value: append($value, #{$property} $duration $method $delay);
    }
  }
  transition: $value;
}

@mixin transition-fast(
  $properties: (
    all
  )
) {
  @include multiple-transition($properties, 0.1s, ease);
}

@mixin transition-medium(
  $properties: (
    all
  )
) {
  @include multiple-transition($properties, 0.15s, ease);
}

@mixin transition-slow(
  $properties: (
    all
  )
) {
  @include multiple-transition($properties, 0.3s, ease);
}
