@import '../../../common/styles/colors';

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  @apply bg-black px-3;
  border-radius: 5px;
  color: white;
  padding: 0;
}

button.react-datepicker__close-icon {
  @apply mr-1;
}
button.react-datepicker__close-icon::after {
  @apply bg-black text-gray-400 text-base transition;
  font-size: 1.3em;
}
button.react-datepicker__close-icon:hover::after {
  @apply text-gray-100;
}

/* Calendar */
.react-datepicker-popper .react-datepicker__header {
  @apply bg-gray-100 rounded-lg border-0 p-2;
}
.react-datepicker-popper .react-datepicker {
  @apply shadow-xl bg-white rounded-xl border-0 p-2;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #6366f1 !important;
}

.react-datepicker__navigation {
  @apply m-2;
  height: 24px !important;
  width: 24px !important;
}

.react-datepicker__navigation span::before {
  top: 12px !important;
  width: 8px !important;
}

.DateFilter {
  display: flex;
  align-items: center;
  min-height: 38px;
  outline: none;
  padding: 0 1.5em;
  border: 1px solid transparent;
  transition: border-color 0.3s ease-out;
  border-radius: 5px;
  background-color: $gray-2;

  &:hover,
  &:focus {
    border-color: $blue;
  }

  &__value {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    line-height: 1;
    font-size: 0.875rem;
    color: rgb(212, 212, 216);
  }
}
