@import '../../../styles/colors';

.flatpickr-calendar {
  color: $lightgray;
  background-color: $darkgray;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.22);

  // fix tailwind cascading
  .hidden {
    display: block;
  }

  .flatpickr-month {
    background-color: $darkgray;
    border-radius: 6px;
  }

  .flatpickr-monthDropdown-months {
    background-color: unset;

    .flatpickr-monthDropdown-month {
      background-color: $darkgray;
    }
  }

  .flatpickr-weekdays {
    margin-top: 8px;
  }

  .flatpickr-weekday {
    background-color: transparent;
    padding: 2px;
    color: $gray;

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .flatpickr-day {
    border: 1px solid transparent;

    &.today {
      border-color: $green;

      &:not(.flatpickr-disabled) {
        color: $lightgray;
      }
    }

    &:hover {
      background-color: $gray;
    }

    &.inRange {
      $color: lighten($darkgray, 6%);

      background-color: $color;
      box-shadow: -5px 0 0 $color, 5px 0 0 $color !important;
    }

    &.startRange {
      background-color: $purple;
    }

    &.selected {
      background-color: $blue;
      border-color: transparent;
    }

    &.endRange {
      background: $purple;
    }
  }

  &.arrowTop:before,
  &.arrowTop:after {
    border-bottom-color: $darkgray;
  }

  &.inline {
    top: 8px;
  }
}
