@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.ChartTabs {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  font-family: $font-display;

  &__tab {
    @include transition-fast();

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $neutral-50;

    &:after {
      @include transition-medium();

      display: block;
      content: '';
      width: 100%;
      height: 2px;
      background: transparent;
    }

    // This thing allows you to get rid of tab content shift,
    // when we change the weight of the active tab.
    &:before {
      display: block;
      content: attr(data-label);
      font-weight: 600;
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:hover:not(&--active) {
      color: $neutral-70;
    }

    &--active {
      font-weight: 600;
      color: $neutral-100;

      &:after {
        background: $neutral-100;
      }
    }
  }

  &__separator {
    width: 2px;
    height: 20px;
    background-color: $neutral-50;
    margin-left: -5px;
    margin-right: -5px;
  }
}
