.EpochsTable {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
    padding-left: 18px;
    padding-right: 18px;
  }
}
