@use 'src/common/components-v2/Form/Input/Input.mixins.scss' as Input;

.TextArea {
  @include Input.box();
  @include Input.box-style-base();
  @include Input.box-style-dark();

  height: unset;
  resize: none;

  &:focus {
    @include Input.focus();
  }

  &::placeholder {
    @include Input.placeholder-dark();
  }

  &:disabled {
    @include Input.disabled();
  }
}
