@import 'src/common/styles/colors';

.MessageBox {
  background-color: rgba(39, 39, 39, 1);
  color: rgb(189, 189, 189);
  padding: 12px 15px;
  margin-top: 25px;
  border-left: 3px solid $darkblue;
  border-radius: 4px;

  &--warning {
    border-left: 3px solid $yellow;
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
    color: white;
    margin-bottom: 6px;

    &-title {
      font-size: 14px;
      color: $neutral-70;
    }

    &-icon {
      color: $blue;

      &--warning {
        color: $yellow;
        width: 18px;
        height: 18px;

        svg {
          width: inherit;
          height: inherit;
        }
      }
    }
  }
}
