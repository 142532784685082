@import 'src/common/styles/colors';

.StakingPage {
  color: white;

  &__header {
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-title {
      font-size: 2rem;
    }
  }

  &__body {
    margin-top: 30px;

    .EventList__content {
      height: initial;
      min-height: 57px;
    }

    .EventList__title {
      font-size: 1.4em;
    }

    .EventList {
      margin-bottom: 20px;

      .button-cta-3 {
        margin-left: 20px;
      }

      &__heading-subject,
      &__event-type {
        flex: 8;
      }

      &__heading-stake,
      &__event-stake {
        flex: 1;
        text-align: right;
      }

      &__heading-actions,
      &__event-action {
        flex: 1;
        text-align: right;
      }

      &__loading {
        display: flex;
        align-items: center;
        justify-content: center;

        .Loader {
          width: 30px;
        }

        &-text {
          margin-top: 0;
          margin-left: 10px;
        }
      }

      &__event-type {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &__external-icon {
        display: inline-block;
        margin-left: 10px;
      }
    }
  }

  .EventList__load-more--no-events {
    padding-top: 20px;
  }
}
