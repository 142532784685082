@import 'src/common/styles/colors';

.ProgressBar {
  background-color: $neutral-35;
  height: 4px;
  width: 100%;

  &__bar {
    background-color: $green;
    border-radius: 4px;
    height: 100%;
    transition: width 0.3s ease;
    animation: blink-animation 1.5s infinite ease;
  }

  &--secondary {
    .ProgressBar__bar {
      background-color: $lime;
    }
  }

  @keyframes blink-animation {
    50% {
      opacity: 0.7;
    }
  }
}
