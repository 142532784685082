@import 'src/common/styles/colors';

.MyAPIKeysPage {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    padding: 20px;
    background-color: $gray-2;
  }

  &__title {
    font-size: 1.3em;
    font-weight: bold;
    display: flex;
    align-items: center;

    .Button {
      height: auto;
    }
  }

  &__content {
    padding: 0 20px;
  }

  &__footer {
    padding: 10px 20px;
    margin-top: 20px;
    background-color: $gray-2;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
  }

  .TableHeading {
    padding-bottom: 10px;
  }

  &__cell {
    padding: 0 25px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 0.9em;
  }

  &__list {
    display: flex;
    flex-direction: column;
    background-color: $gray-2;
    border-radius: 8px;
  }

  &__createdAt {
    font-size: 12px;
    color: #999;
  }

  &__deleteIcon {
    cursor: pointer;

    svg {
      width: 14px;
    }
  }

  &__newApiKeyButton {
    margin: 20px 0;
    cursor: pointer;

    &-container {
      text-align: right;
    }
  }

  &__newApiKeyContainer {
    display: none;

    &.active {
      display: block;
    }
  }

  &__confirmButton {
    text-align: center;
  }

  &__modal {
    max-width: 830px;
    width: 100%;
    padding: 0;
  }

  &__modal-description {
    margin: 20px 0;
    color: $lightgray-2;
    font-style: italic;

    a {
      color: white;
      font-style: normal;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__modal-item {
    display: flex;
    margin: 10px 0;
    align-items: center;

    &-label {
      padding: 10px;
      background-color: $gray-2;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
      font-style: italic;
    }

    &-value {
      padding: 10px;
      background-color: $neutral-30;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      font-family: 'Courier New', Courier, monospace;
      word-break: break-all;
    }
  }

  &__confirm-copy {
    margin: 20px 0;
    color: $lightgray-2;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
  }

  &__delete-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    &-description {
      margin-top: 20px;
      color: $lightgray-2;
    }

    &-key {
      background-color: $gray-2;
      border-radius: 4px;
      padding: 10px;
      margin: 20px 0;
      width: 100%;
      text-align: center;
    }
  }
}
