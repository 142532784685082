@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';

.GroupPanel {
  $module: &;

  display: flex;
  gap: 80px;
  padding: 24px 20px;
  background-color: rgba(white, 0.1);
  border-radius: 6px;
  border: 1px solid $neutral-30;
  box-shadow: 0px 2px 11px 1px rgba(0, 0, 0, 0.22);

  .Field {
    min-width: 70px;

    &__label {
      font-size: 16px;
      line-height: 23px;
      font-weight: 400;
      color: $neutral-50;
    }

    &__value {
      margin-top: 2px;
      font-size: 24px;
      line-height: 26px;
      font-weight: 700;
      color: $lightgray;
    }
  }

  .Address {
    display: flex;
    align-items: center;
    margin-top: -2px;

    &__value {
      @include lead-lg();

      color: $accent-blue-30;
      margin-right: 6px;
      word-break: keep-all;
      line-height: 130%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    &__action {
      svg {
        width: 24px;
        height: 24px;
      }

      &:hover,
      &:focus {
        opacity: 0.8;
      }
    }
  }

  &__navigation-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-width: 240px;

    > .Field {
      margin-right: 20px;
    }

    #{$module}__controls {
      display: flex;
      gap: 10px;
    }
  }

  &__address-section {
    display: flex;
    align-items: center;
    min-width: 320px;
  }

  &__info-section {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 80px;
  }

  &__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    #{$module}__tag {
      font-size: 16px;
      line-height: 23px;
      font-weight: 400;
      padding: 3px 10px;
      flex-shrink: 0;
      color: $neutral-50;
      background-color: $neutral-30;
      border-radius: 4px;
    }
  }

  > *:not(:last-child) {
    position: relative;

    &:after {
      position: absolute;
      right: -40px;
      top: 7%;
      content: '';
      height: 86%;
      width: 1px;
      background-color: $neutral-40;
    }
  }

  @media #{$breakpoint-xl-down} {
    gap: 60px;

    > *:not(:last-child) {
      &:after {
        right: -30px;
      }
    }

    &__info-section {
      gap: 30px;
    }
  }

  @media #{$breakpoint-lg-down} {
    gap: 40px;
    padding: 18px;

    .Field {
      &__label {
        font-size: 15px;
      }

      &__value {
        font-size: 19px;
      }
    }

    .Address {
      &__value {
        font-size: 18px;
      }
    }

    &__tags {
      #{$module}__tag {
        font-size: 14px;
        padding: 2px 6px;
      }
    }

    &__info-section {
      gap: 26px;
      justify-content: space-between;
    }

    &__address-section {
      min-width: 290px;
    }

    > *:not(:last-child) {
      &:after {
        right: -20px;
      }
    }
  }

  @media #{$breakpoint-md-down} {
    flex-direction: column;
    gap: 32px;

    > *:not(:last-child) {
      &:after {
        right: 0;
        top: unset;
        width: 100%;
        height: 1px;
        bottom: -16px;
      }
    }

    &__info-section {
      justify-content: space-between;
    }

    &__address-section {
      min-width: unset;
    }
  }

  @media #{$breakpoint-sm-down} {
    &__info-section {
      justify-content: flex-start;
    }

    &__tags-field {
      display: none;
    }
  }
}
