@import 'src/common/styles/colors';

.AgentDeploymentPreview {
  $module: &;

  width: 100%;
  max-width: 860px;
  background-color: $darkgray;
  position: relative;
  font-size: 14px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 6px;

  &__item {
    display: grid;
    grid-template-columns: 190px 1fr;
    gap: 20px;
    margin-bottom: 10px;

    &-label {
      color: rgb(197, 176, 176);
    }

    &-value {
      color: $lightgray-2;
      word-wrap: break-word;
      overflow-y: auto;

      a:hover {
        color: white;
      }
    }
  }

  &__network {
    margin-right: 10px;
  }

  &__documentation {
    &-item {
      margin-bottom: 6px;
    }

    &-source {
      color: $neutral-45;
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: rgba(black, 0.3);
    backdrop-filter: blur(2px);
    color: $lightgray-2;

    .Loader {
      margin-bottom: 10px;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    column-gap: 20px;
    align-items: baseline;
    margin-top: 30px;
  }

  &__submit-button {
    width: 100%;
    max-width: 130px;
  }
}
