@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.AccountPage {
  &__buy-plan-button {
    width: 100%;
    max-width: 170px;
  }

  &__plans {
    border-radius: 6px;
    text-align: left;
    overflow: visible;

    .Table {
      overflow: visible;
    }
  }

  &__plans-title {
    font-size: 2em;
    font-weight: 400;
    margin-bottom: 40px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__plans-cell {
    padding: 14px 23px;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      overflow: overlay;
    }

    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #555;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  &__actions-cell {
    justify-content: center;
  }

  &__plan-status {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
  }

  &__empty-plans {
    text-align: center;

    &__title {
      font-size: 1.1em;
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    &__description {
      font-size: 0.9em;
      color: $lightgray;
      margin-bottom: 2em;
    }
  }

  &__plan-row {
    height: 70px;

    &--expired {
      .AccountPage__plan-renew-date {
        color: $red;
      }

      .AccountPage__plan-status {
        .icon-wrapper {
          background-color: rgba(#3e4660, 0.5);
          border-radius: 6px;
          padding: 2px;

          svg {
            color: $yellow;
            font-size: 0.9em;
          }
        }
      }
    }

    .RenewalStatus {
      display: flex;

      &__label {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        align-items: flex-start;
      }

      .Loader {
        width: 32px;
      }

      .Button {
        height: auto;
        padding: 0;
      }

      .icon-wrapper {
        background-color: rgba(#3e4660, 0.5);
        border-radius: 6px;
        padding: 2px;

        svg {
          color: $yellow;
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  &__plan-expired-text {
    color: $red;
  }

  &__keys {
    margin-bottom: 40px;

    .TableHeading__sub-headings {
      display: none;
    }
  }

  &__transactions {
    margin-top: 20px;
  }
}

.RenewalStatus {
  display: flex;

  &__label {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }

  &__modal {
    &-description {
      margin-top: 30px;
      font-size: 14px;
    }

    &-button {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }

  .Loader {
    width: 32px;
  }

  .Button {
    height: auto;
    padding: 0;
  }

  .icon-wrapper {
    background-color: rgba(#3e4660, 0.5);
    border-radius: 6px;
    padding: 2px;

    svg {
      color: $yellow;
      width: 16px;
      height: 16px;
    }
  }

  &__renew-button {
    padding: 5px 20px !important;
  }
}

.PlanStatusActions {
  position: relative;
  display: flex;
  justify-content: flex-end;

  &__dots {
    transition: all 0.2s ease-in;
    z-index: 0;
  }

  &__menu {
    background-color: $darkgray;
    position: absolute;
    color: $lightgray-2;
    right: 0;
    top: 10px;
    margin-top: 13px;
    text-align: left;
    opacity: 0;
    transition: all 0.2s ease-in;
    pointer-events: none;
    z-index: 1;
    width: 200px;
    border-radius: 12px;

    &-item {
      display: block;
      width: 100%;
      padding: 5px 10px;
      text-align: left;
      transition: all 0.2s ease-in;
      color: white;

      &:hover {
        background-color: $gray;
        border-radius: 12px;
      }

      &--red {
        color: $red;

        &:hover {
          color: $red !important;
        }
      }

      &--disabled {
        border-radius: 12px;
        opacity: 0.5;
        pointer-events: none;
      }
    }

    &:disabled {
      color: $gray;
      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }

  &__modal {
    .Modal__children {
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  }

  &--expanded {
    .PlanStatusActions__dots {
      color: $gray;
    }

    .PlanStatusActions__menu {
      opacity: 1;
      pointer-events: initial;
    }
  }
}
