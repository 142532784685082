@use 'sass:color';
@import "src/common/styles/colors";

.NetworkStatus {
  $pulse-color: white;

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;

  &__status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    color: $neutral-70;
    padding: 8px 14px;
    padding-right: 20px;
    background: rgba(255, 255, 255, 0.07);
    border-radius: 6px;
    overflow: hidden;
  }

  &__pulse {
    border-radius: 50%;
    box-shadow: 0 0 0 $pulse-color;
    animation: pulse2 2.25s infinite;
  }


  @keyframes pulse2 {
    0% {
      box-shadow: 0 0 0 0 color.scale($pulse-color, $alpha: -60%);
    }
    70% {
      box-shadow: 0 0 0 15px color.scale($pulse-color, $alpha: -100%);
    }
    100% {
      box-shadow: 0 0 0 0 color.scale($pulse-color, $alpha: -100%);
    }
  }
}
