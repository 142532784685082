@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';

.BotMetrics {
  text-align: left;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 14px;
  }

  &__title {
    @include lead-lg();
  }

  &__description {
    color: $neutral-60;
    max-width: 600px;
  }

  &__controls {
    display: grid;
    align-items: center;
    gap: 24px;
    grid-template-columns: 2fr auto;
    margin-bottom: 20px;
  }

  &__filters {
    display: flex;
    gap: 24px;
    align-items: center;

    > *:last-child {
      margin-right: auto;
    }
  }

  &__interval {
    max-width: 200px;
    width: 100%;
  }

  &__chain {
    max-width: 200px;
    width: 100%;
  }

  &__metric {
    max-width: 300px;
    width: 100%;
  }

  @media #{$breakpoint-md-down} {
    &__controls {
      grid-template-columns: 1fr;
    }
  }

  @media #{$breakpoint-sm-down} {
    &__header {
      align-items: flex-start;
      flex-direction: column;
      gap: 15px;
    }

    &__controls {
      grid-template-columns: 1fr;
      gap: 14px;
    }

    &__filters {
      gap: 14px;
      flex-direction: column;
    }

    &__chain,
    &__interval,
    &__metric {
      max-width: 100%;
    }
  }
}

.LegendContainer {
  display: flex;
  row-gap: 20px;
  column-gap: 48px;
  margin-top: 30px;

  .LegendItem {
    display: flex;
    align-items: center;
    gap: 15px;

    &__shape {
      display: block;

      &--line {
        height: 4px;
        width: 20px;
        background: grey;
        border-radius: 1px;
      }
    }

    &__label {
      font-family: $font-display;
      font-size: 13px;
      line-height: 140%;
      color: $neutral-60;
    }
  }
}
