@use '../Input.mixins' as Input;

.InputComponent {
  position: absolute;
  top: 0;
  height: Input.$height;

  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100%;
  }

  &--no-mouse {
    pointer-events: none;
  }
}
