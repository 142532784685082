@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.TransactionItem {
  margin-bottom: 25px;
  font-size: 14px;
  color: $neutral-70;

  &__label {
    margin-bottom: 10px;
  }

  &__link {
    display: inline-flex;
    vertical-align: sub;
  }
}

.PendingTransactionList {
  background-color: $neutral-15;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  text-align: left;
  padding: 20px 15px;
  z-index: 1;

  &__syncing {
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 16px;
    color: $neutral-70;
  }

  &__description {
    color: $lightgray-3;
    font-size: 13px;
    max-width: 240px;
  }
}
