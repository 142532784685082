@import 'src/common/styles/colors';

.AnalysisResult {
  $module: &;

  &__heading {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: flex-end;
    border-bottom: 1px solid $neutral-20;
    padding-bottom: 8px;
    margin-bottom: 15px;

    #{$module}__title {
      font-size: 24px;
      font-weight: 700;
      line-height: 1.3;
      color: $lightgray;
    }

    #{$module}__time {
      font-size: 16px;
      color: $neutral-50;
    }
  }

  &__panel {
    margin-bottom: 10px;
  }
}
