@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/line-clamp';

.BotDetailsOverlay {
  width: 100%;
}

.BotDetails {
  $module: &;

  display: flex;
  flex-direction: column;
  position: relative;
  padding: 40px 35px;
  border-radius: 0.75rem;
  line-height: 140%;
  background: $gray-4;
  text-align: left;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &__header {
    width: 100%;
    max-width: 700px;
  }

  &__actions {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 25px;
    right: 35px;
    width: 400px;
  }

  .BotDetailsPrice {
    font-family: $font-display;
    font-size: 18px;
    color: $neutral-90;
    font-weight: 400;

    &__value {
      font-weight: 500;
    }

    &__period {
      opacity: 0.65;
      margin-left: 6px;
    }

    @media #{$breakpoint-md-down} {
      font-size: 26px;
    }

    @media #{$breakpoint-sm-down} {
      font-size: 18px;
      text-align: center;
    }
  }

  &__action {
    max-width: 150px;
    width: 100%;

    &--main {
      max-width: 180px;

      .Loader {
        width: 24px;
      }
    }
  }

  &__headings {
  }

  &__title {
    display: inline;
    vertical-align: middle;
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 140%;
    max-width: 600px;
    margin-right: 16px;
  }

  &__meta {
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 10px;
    font-size: 14px;
    font-weight: 500;
    margin-top: 6px;

    &-item {
      position: relative;
      color: $neutral-45;

      a {
        color: $neutral-45;
      }

      &__prefix {
        font-weight: 400;
        color: $neutral-43;
      }

      &:not(:last-child)::after {
        position: absolute;
        content: '';
        right: -10px;
        top: calc(50% - 1px);
        width: 4px;
        height: 4px;
        background-color: $neutral-45;
        border-radius: 50%;
      }

      &--status {
        background: $neutral-30;
        color: $neutral-60;
        padding: 1px 8px;
        border-radius: 4px;
      }
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    gap: 48px;
    margin-top: 25px;

    &-content {
      max-width: 720px;
      width: 100%;
    }

    &-chart {
      margin-top: 20px;
      width: 100%;
      max-width: 500px;
      text-align: right;
      height: 180px;
    }
  }

  &__chart {
    margin-right: -25px;
  }

  &__networks {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 6px;
    margin-bottom: 26px;
    max-width: 400px;
  }

  &__short-description {
    width: 100%;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color: $neutral-90;
    max-width: 80%;
  }

  &__long-description {
    @include line-clamp(3, 21px);

    font-size: 15px;
    margin-top: 20px;
    color: $neutral-45;

    &--expanded {
      display: block;
    }
  }

  #{$module}__read-more-button {
    margin-top: 10px;
  }

  &__footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__links {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;
  }

  &__help {
    text-align: right;

    &-title {
      font-size: 16px;
      color: $neutral-40;
    }

    &-button {
      color: $neutral-60;
      padding: 2px 3px;
      height: 34px;
    }
  }

  &__explore-product-button {
    border: 1px solid $neutral-50;
  }

  @media #{$breakpoint-lg-down} {
    &__body {
      &-chart {
        max-width: 400px;
      }
    }
  }

  @media #{$breakpoint-md-down} {
    &__body {
      flex-direction: column;
      gap: 15px;
      margin-top: 20px;
    }

    &__short-description {
      max-width: 100%;
    }

    &__body-chart {
      height: 150px;
      max-width: 100%;
      margin-top: 10px;
    }

    &__actions {
      position: static;
      order: 10;
      display: flex;
      margin-top: 80px;
      width: 100%;

      > * {
        flex: 1;
        width: 100%;
        max-width: 100%;
      }
    }

    &__footer {
      align-items: flex-start;
      flex-direction: column-reverse;
    }

    &__help {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 10px;

      &-title {
        margin-right: 8px;
      }
    }

    &__links {
      margin-top: 10px;
    }
  }

  @media #{$breakpoint-sm-down} {
    padding: 15px;

    &__body {
      margin-top: 20px;
    }

    &__actions {
      flex-direction: column;

      > * {
        flex: unset;
      }
    }
  }
}
