@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.ProfilePage {
  color: white;
  // margin-top: 70px;
  font-family: $font-display;

  .MessageBox {
    text-align: left;
    margin-bottom: 40px;
  }

  &__header {
    display: flex;
    column-gap: 40px;
    align-items: center;
  }

  &__chevron {
    margin-right: 6px;
  }

  &__profile-pic {
    flex-shrink: 0;

    img {
      border-radius: 50%;
      width: 140px;
      height: 140px;
      //box-shadow: 0px 0px 0px $darkblue, 0px 0px 20px $darkblue;
      border: 3px solid $secondary-purple;
    }
  }

  &__profile-subscriptions {
    display: flex;
    align-items: center;
  }

  &__profile-address {
    font-size: 40px;
    font-weight: 600;
    text-align: left;
    margin-right: 20px;
  }

  &__profile-tag-container {
    display: flex;
    gap: 10px;
    margin-top: 15px;
  }

  &__profile-tag {
    padding: 6px 10px;
    font-weight: 500;
    font-size: 16px;
    background: rgba(133, 133, 133, 0.28);
    border-radius: 6px;
  }

  &__profile-subs {
    @include transition-medium();

    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 18px;
    background-color: $secondary-purple;
    border-radius: 10px;
    overflow: hidden;

    &-LHS {
      padding: 5px 10px;
    }

    &-RHS {
      padding: 5px 8px;
      min-width: 36px;
      background-color: #463eac;
    }

    &:hover {
      background-color: lighten($secondary-purple, 4%);
    }
  }

  &__copy-address {
    display: flex;
    align-items: center;
    font-size: 20px;
    color: #8b8b8b;

    & svg {
      width: 18px;
      height: 18px;
    }
  }

  &__tabs {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
