@import 'src/common/styles/variables';
@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/transitions';

.WalletConnector {
  color: white;
  font-weight: 500;
  font-family: 'neue-haas-display';

  &__button {
    display: inline-block;
    padding: 8px 22px;
    font-size: 16px;
    line-height: 23px;
    font-weight: 500;
    user-select: none;
    cursor: pointer;
    transition: all 0.2s ease-in;
    box-shadow: 0px 0px 0px 0px white;
    background: linear-gradient(
      87.45deg,
      $accent-green-50 0%,
      $accent-blue-50 101.45%
    );
    background-clip: text;
    color: transparent;
    border: 1px solid $accent-blue-50;
    border-radius: 34px;

    &:hover {
      box-shadow: 0px 0px 8px 0px white;
    }
  }

  &__hash-container {
    @include transition-medium();

    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 10px;
    position: relative;
    min-width: 200px;
    padding: 8px 22px;
    border: 1px solid $neutral-75;
    border-radius: 34px;
    background-color: #181818;

    &:hover,
    &--expanded {
      border-color: $neutral-90;
    }
  }

  &__menu-icon {
    @include transition-medium();

    cursor: pointer;
  }

  // TODO
  // Refactor the menu component so that it uses Popover,
  // which will detect the position of the menu and align it appropriately.

  &__menu {
    @include transition-medium();

    position: absolute;
    right: 0;
    width: 200px;
    margin-top: 20px;
    padding: 6px 0;
    text-align: left;
    opacity: 0;
    pointer-events: none;
    border: 1px solid $neutral-40;
    border-radius: 16px;
    overflow: hidden;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.4);
    color: $neutral-100;
    background-color: #181818;

    &-item {
      p {
        padding-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;
        user-select: none;

        &:hover {
          background-color: $gray-2;
        }
      }
    }
  }

  &__wrong-network {
    display: flex;

    &-label {
      display: flex;
      align-items: center;
      font-size: 16px;
      line-height: 23px;
      font-weight: 500;
      color: $lightgray-2;
      margin-right: 20px;

      &::before {
        content: '•';
        font-size: 30px;
        color: $red;
        line-height: 17px;
        margin-right: 5px;
      }
    }

    &-button {
      @extend .WalletConnector__button;
    }
  }

  &--menu-expanded {
    .WalletConnector__menu-icon {
      transform: rotate(180deg);
    }

    .WalletConnector__menu {
      opacity: 1;
      pointer-events: initial;
    }
  }

  @media #{$breakpoint-md-down} {
    &__menu {
      bottom: calc(100% + 10px);
    }
  }
}
