@use './Mark.mixins' as Mark;

@import 'src/common/styles/colors';
@import 'src/common/styles/variables';
@import 'src/common/styles/mixins/transitions';

.Mark {
  @include transition-medium();

  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  border: 1px solid $neutral-70;
  background-color: transparent;

  > svg {
    @include transition-medium();

    height: 16px;
    width: 16px;
  }

  &:hover:not(&--disabled):not(&--checked) {
    @include Mark.mark-hover();
  }

  &--checked {
    color: $neutral-100;
    border: 1px solid $secondary-purple;
    background-color: $secondary-purple;
  }

  &--disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  &--checkbox {
    border-radius: 3px;
  }

  &--radio {
    border-radius: 50%;
  }
}
