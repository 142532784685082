@import 'src/common/styles/colors';

.NotificationList {
  &__head {
    border-bottom: 1px solid rgba(white, 0.3);
    padding-left: 10px;
    padding-right: 10px;
  }

  &__head,
  &__notification {
    display: flex;
    gap: 32px;
    text-align: left;
    align-items: center;
    padding-bottom: 20px;
  }

  &__notification {
    border-bottom: 1px solid rgba(white, 0.1);
    padding-top: 20px;
    transition: all 0.2s ease-out;
    padding-left: 10px;
    padding-right: 10px;

    &:hover {
      background-color: rgba(white, 0.03);
    }
  }

  &__head-source,
  &__notification-source {
    flex: 4 0 0%;
    display: flex;
    align-items: center;
  }

  &__head-endpoint,
  &__notification-endpoint {
    flex: 4 0 0%;
    display: flex;
    align-items: center;
  }

  &__head-plan,
  &__notification-plan {
    flex: 3 0 0%;
    display: flex;
    align-items: center;
  }

  &__head-renew-date,
  &__notification-renew-date {
    flex: 3 0 0%;
    display: flex;
    align-items: center;

    &--expired {
      color: $red;
    }

    &--buy-plan {
      max-width: 120px;
      width: 100%;
    }
  }

  &__head-renewal-status,
  &__notification-renewal-status {
    flex: 3 0 0%;
    display: flex;
    align-items: center;
  }

  &__head-actions,
  &__notification-actions {
    flex: 1 0 0%;
    display: flex;
    justify-content: center;
  }

  &__notification-source,
  &__notification-endpoint {
    font-size: 0.9em;
    word-break: break-word;
  }

  &__notification-source-name {
    word-break: break-word;
  }

  &__notification-source-value {
    font-size: 0.8em;
    word-break: break-word;
    color: $gray;
  }

  &__empty {
    margin-top: 30px;
  }

  &__confirmationModal {
    .Modal__header {
      color: red;
      font-weight: 600;
    }
  }

  &__valid {
    color: $green;
    font-weight: 600;
  }

  .RenewalStatus {
    display: flex;

    &__label {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }

    .Loader {
      width: 32px;
    }

    .Button {
      height: auto;
      padding: 0;
    }

    .icon-wrapper {
      background-color: rgba(#3e4660, 0.5);
      border-radius: 6px;
      padding: 2px;

      svg {
        color: $yellow;
        width: 16px;
        height: 16px;
      }
    }
  }
}

.NotificationBadge {
  background-color: $gray;
  color: white;
  display: inline-block;
  padding: 0.2em 0.6em;
  font-size: 0.9em;
  text-transform: capitalize;
  border-radius: 4px;
  margin-right: 15px;
  word-break: normal;

  &--slack {
    background-color: $magenta;
  }

  &--telegram {
    background-color: $blue;
  }

  &--discord {
    background-color: $purple;
  }

  &--webhook {
    background-color: $green;
  }
}

@media screen and (max-width: 740px) {
  .NotificationList__notification {
    display: block;
  }

  .NotificationList__notification-source,
  .NotificationList__notification-endpoint,
  .NotificationList__notification-actions {
    margin-bottom: 10px;
  }
}
