@import '../../../common/styles/colors';
@import '../../../common/styles/variables';

.AgentDeploymentPage {
  color: white;
  text-align: left;

  &__header {
    text-align: center;
    margin-bottom: 10px;
  }

  &__title {
    font-family: $font-display;
    font-weight: 500;
    font-size: 2rem;
  }

  &__description {
    font-family: $font-display;
    color: $neutral-45;
    margin-top: 4px;
  }

  &__denied {
    text-align: center;
    margin-top: 30px;
    font-size: 1.2em;
    color: $lightgray-2;
    border: 1px solid $gray;
    padding: 100px;
  }

  &__message {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__message-link {
    margin-left: 0.3em;
    color: $darkblue;
    transition: all 0.2s ease-in;

    &:hover {
      color: $blue;
    }
  }

  &__form {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    margin-top: 30px;
  }
}
