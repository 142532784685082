@import 'src/common/styles/variables';
@import 'src/common/styles/colors';

.FancyContainer {
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 2px 11px rgba(0, 0, 0, 0.22);
  font-family: $font-display;
  text-align: left;

  &--default {
    background: $neutral-20;
  }

  &--gradient {
    background: linear-gradient(180deg, #0f0f0f 0%, #1f1936 100%);
  }

  &__title {
    font-weight: 700;
    font-size: 24px;
    line-height: 140%;
    margin-bottom: 20px;
  }

  &__filters {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 30px;
  }

  &__full-width {
    margin-left: -30px;
    margin-right: -30px;
  }
}
