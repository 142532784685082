@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';

.BotSearchPage {
  $module: &;

  color: white;
  text-align: left;
  position: relative;

  &__docs-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
    padding-left: 18px;
    padding-right: 18px;
    border-radius: 10px;
  }

  &__title {
    @include h1-responsive;

    text-align: left;
    margin-top: 90px;
    margin-bottom: 38px;
  }

  &__description {
    @include lead-responsive();

    color: $gray;
    margin-bottom: 30px;
    max-width: 879px;
  }

  &__form {
    display: flex;
    margin-top: 32px;
    margin-bottom: 80px;
    width: 100%;
    max-width: 694px;

    &__input {
      flex: 1;

      input {
        border-radius: 6px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }

    &__submit {
      border-radius: 6px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &__filter-panel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 80px;
    margin-bottom: 24px;
  }

  &__body {
    position: relative;
    min-height: 200px;
  }

  &__bots {
    display: grid;
    gap: 24px;
    grid-template-columns: 1fr 1fr;
  }

  &__notification {
    @include lead-responsive();

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: pre;
    border: 1px solid $neutral-30;
    color: $neutral-50;
    min-height: 200px;
    border-radius: 10px;

    &-button {
      color: $accent-blue-30;
    }
  }

  @media #{$breakpoint-lg-down} {
    &__title {
      margin-top: 50px;
      margin-bottom: 20px;
    }
  }

  @media #{$breakpoint-md-down} {
    &__docs-button {
      position: static;
      height: 50px;
      padding-left: 12px;
      padding-right: 12px;
    }

    &__title {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &__form {
      margin-bottom: 34px;
    }

    &__filter-panel {
      margin-top: 34px;
    }
  }

  &__pagination {
    margin-top: 60px;
    text-align: center;

    // apply for button
    > * {
      max-width: 200px;
      width: 100%;
    }
  }

  @media #{$breakpoint-sm-down} {
    &__filter-panel {
      flex-direction: column;
      align-items: flex-start;
    }

    &__bots {
      grid-template-columns: 1fr;
    }

    &__search-row {
      display: flex;
      row-gap: 10px;
      flex-direction: column;
    }
  }
}
