@import 'src/common/styles/colors';
@import 'src/common/styles/variables';

.MenuList {
  text-align: left;
  padding: 6px 0;
  margin: 0;
  list-style: none;
  min-width: 60px;

  > li {
    padding: 0;
    margin: 0;
  }

  .MenuListItem {
    &__action {
      width: 100%;
      text-align: left;
      font-weight: 400;
      font-size: 16px;
      color: $neutral-50;
      padding: 8px 16px;

      &:hover {
        background-color: $neutral-30;
        color: $neutral-100;
      }

      &:disabled {
        opacity: 0.5;
        cursor: auto;
      }
    }
  }
}
