.Loader {
  display: inline-block;

  &__image {
    display: inline-block;
    width: 64px;
    animation: spin 1.4s ease-out infinite;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
