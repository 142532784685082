@import 'src/common/styles/colors';

.BotList {
  background-color: #19191a;
  border-radius: 6px;
  position: relative;

  &__head {
    background-color: #19191a;
    display: flex;
    text-align: left;
    padding: 20px 20px;
  }

  .BotList__heading {
    cursor: pointer;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    --tw-text-opacity: 1;
    color: rgb(255, 255, 255);
    text-align: right;
    flex: 1 0;
    outline: none;

    &-dev {
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      --tw-text-opacity: 1;
      color: rgb(255, 255, 255);
      text-align: right;
      flex: 2 0;
      outline: none;
    }

    &-update {
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      --tw-text-opacity: 1;
      color: rgb(255, 255, 255);
      text-align: right;
      flex: 1 0;
      outline: none;
    }

    &-update {
      padding-left: 10px;
    }

    &-id {
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      --tw-text-opacity: 1;
      color: rgb(255, 255, 255);
      text-align: left;
      outline: none;
      flex: 3 0;
    }

    &-status {
      cursor: pointer;
      font-size: 0.75rem;
      line-height: 1rem;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 0.05em;
      --tw-text-opacity: 1;
      color: rgb(255, 255, 255);
      text-align: right;
      flex: 1 0;
      outline: none;
    }
  }

  &__body {
    min-height: 100px;
    background-color: #272727;
  }

  &__bot-row {
    display: flex;
    flex-wrap: wrap;
    padding: 10px 20px;
    align-items: center;
  }

  &__bot-description {
    flex: 3 0;
    text-align: left;

    a {
      color: #d1d5db;
      transition: all 0.2s ease-out;

      &:hover {
        color: white;
      }
    }

    .copy-icon {
      margin: 0;
      width: 10px;

      svg {
        width: 1.1rem;
        height: 1.1rem;
      }
    }
  }

  &__bot-chain {
    display: flex;
    flex-wrap: wrap;

    a {
      width: auto;
    }

    &-icon {
      display: inline-block;
      img {
        max-width: 80%;
        border-radius: 50%;
      }
    }

    &-icons {
      margin-left: 10px;
      display: flex;
      align-items: center;
    }
  }

  &__bot-id {
    font-size: 0.9rem;
    color: #71717a;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  &__dev {
    flex: 2 0;
    text-align: right;
  }

  &__bot-update {
    flex: 1 0;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__bot-status {
    flex: 1 0;
    text-align: right;
  }

  &__status-box {
    background-color: hsl(0, 0, 30);
    padding: 4px 6px;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;

    &::before {
      content: '•';
      font-size: 30px;
      line-height: 17px;
      margin-right: 5px;
    }

    &--enabled {
      background-color: rgb(209, 250, 229);
      color: rgb(6, 95, 70);
    }
  }

  &__list-empty {
    text-align: center;
    padding-top: 35px;
    font-style: italic;
  }

  &__bot-date {
    margin: 0px 16px;
  }

  &__loading {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    overflow: hidden;
    background-color: rgba(black, 0.6);
    backdrop-filter: blur(1px);

    &-text {
      margin-top: 20px;
    }
  }

  @media screen and (max-width: 900px) {
    .BotList__heading-status {
      display: none;
    }

    .BotList__bot-status {
      display: none;
    }
  }

  @media screen and (max-width: 600px) {
    .BotList__heading-dev {
      display: none;
    }

    .BotList__dev {
      display: none;
    }

    .BotList__bot-id {
      display: none;
    }

    .BotList__bot-version {
      display: none;
    }

    .BotList__heading-update {
      flex: 2 0;
    }
    .BotList__bot-update {
      flex: 2 0;
    }
  }
}
