@import 'src/common/styles/colors';
@import 'src/common/styles/mixins/typography';

.Alerts {
  svg {
    display: inline;
  }

  &__list {
    position: relative;
    min-height: 300px;
    border-radius: 6px;
    background-color: rgba(white, 0.1);

    &-title {
      @include font-title;
      text-align: left;
      padding: 1rem 1.25rem;
    }

    &-header {
      display: flex;
      justify-content: space-between;
    }
  }

  &__external-link {
    text-align: right;
    img {
      border-radius: 50%;
      transition: all 0.2s ease-out;

      &:hover {
        box-shadow: 0px 0px 15px 0px rgba(white, 0.5);
      }
    }
  }
}

.AlertsTable {
  &__head {
    display: flex;
    text-align: left;
    padding-top: 10px;
    // padding-left: 8px;
  }

  &__heading {
    cursor: pointer;
    padding: 0.5rem 0;
    // padding-left: 1rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    letter-spacing: 0.05em;
    width: 200px;
    --tw-text-opacity: 1;
    color: white;
    flex: 7 0 0%;
    text-align: left;
    outline: none;
    border-bottom: 1px solid $gray;

    &[data-name='Severity'] {
      flex: 2 0 0%;
      padding-left: 12px;
    }

    &[data-name='Date'] {
      flex: 2 0 0%;
      text-align: right;
    }

    &[data-name='Node Operators'] {
      flex: 2 0 0%;
      text-align: right;
    }

    &[data-name='Alert'] {
      flex: 6 0 0%;
    }

    &[data-name='Details'] {
      padding-left: 4px;
    }

    &:last-child {
      flex: 1 0 0%;
    }
  }

  &__alert-row {
    display: flex;
    --tw-text-opacity: 1;
    color: $lightgray;
    font-size: 0.875rem;
    line-height: 1.25rem;
    border-left: 4px solid transparent;
    animation-duration: 1s;
    animation-fill-mode: forwards;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    padding: 0.6rem 0;
    height: 44px;

    &:hover {
      background-color: rgba(white, 0.1);
      border-left: 4px solid $blue;
    }
  }

  &__alert-severity,
  &__alert-name,
  &__alert-description,
  &__alert-dates,
  &__alert-actions,
  &__alert-scanners,
  &__alert-hash {
    text-align: left;
    flex: 7 0 0%;
    overflow: auto;
    word-break: break-word;
  }

  &__alert-description {
    padding-left: 4px;
  }

  &__alert-description,
  &__alert-name {
    &::-webkit-scrollbar {
      width: 3px;
      height: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: $gray-2;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #555;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #555;
    }
  }

  &__alert-severity {
    flex: 2 0 0%;
    padding-left: 8px;

    &-label {
      display: inline-block;
      padding: 0.1rem;
      min-width: 80px;
      text-align: center;
      border-radius: 5px;
      font-size: 12px;
      background-color: $black;
    }
  }

  &__alert-dates {
    flex: 2 0 0%;
    text-align: right;
  }

  &__alert-dates-small {
    display: none;
  }

  &__alert-time {
    color: $lightgray-2;
  }

  &__alert-actions {
    flex: 1 0 0%;
    text-align: center;
    overflow: initial;
  }

  &__alert-scanners {
    flex: 2 0 0%;
    text-align: right;
  }

  &__alert-name {
    color: rgb(197, 176, 176);
    display: inline-flex;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: baseline;
    flex: 6 0 0%;

    button {
      transition: all 0.2s ease-out;

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__alert-projects {
    display: flex;
    row-gap: 10px;
    column-gap: 10px;
    flex-wrap: wrap;
    align-items: baseline;
    overflow: initial;
    column-gap: 20px;
    margin-top: 10px;
  }

  &__alert-project {
    padding: 4px 8px;
    border-radius: 4px;
    background-color: rgba(white, 0.1);
    color: $lightgray;
  }

  &__alert-network {
    padding-left: 1rem;
    color: $lightpurple;
    font-weight: 500;
  }

  &__alert-network-name,
  &__alert-network-name-in-alert {
    color: $lightgray-2;
    font-size: 0.7rem;
    line-height: 1.5rem;
    text-transform: lowercase;

    &-in-alert {
      display: none;
    }
  }

  &__no-alerts {
    padding-top: 20px;
    color: white;
  }

  &__alert-external {
    background-color: rgba($yellow, 0.4);
    display: inline-block;
    padding: 2px 14px;
    color: white;
    border-radius: 6px;
    text-transform: capitalize;
  }
}

.refresh-button {
  color: black;
  margin: 1rem 1.25rem;
  padding: 0.5rem;
  background-color: $black;
  color: $lightgray-2;
  display: flex;
  font-size: 14px;
  outline: none;
  border: 1px solid transparent;
  border-radius: 5px;
  transition: border-color 0.2s ease-out;

  &:hover {
    border-color: $blue;
  }

  &__icon {
    padding-top: 1px;
    padding-right: 10px;

    svg {
      display: block;
    }
  }
}

@media screen and (max-width: 1200px) {
  .AlertsTable__heading[data-name='Details'],
  .AlertsTable__heading[data-name='Node Operators'],
  .AlertsTable__alert-description,
  .AlertsTable__alert-scanners {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .AlertsTable__heading[data-name='Severity'],
  .AlertsTable__alert-severity,
  .AlertsTable__heading:last-child,
  .AlertsTable__alert-actions {
    display: none;
  }

  .AlertsTable__alert-via {
    display: none;
  }
}

@media screen and (max-width: 720px) {
  .AlertsTable__heading[data-name='Protocol'],
  .AlertsTable__alert-network,
  .AlertsTable__alert-network {
    display: none;
  }

  .AlertsTable__heading[data-name='Date'],
  .AlertsTable__alert-dates {
    text-align: right;
    padding-right: 10px;
  }

  .AlertsTable__alert-dates {
    font-size: 0.8em;
  }

  .AlertsTable__heading[data-name='Alert'],
  .AlertsTable__alert-name {
    padding-left: 10px;
  }
}

@media screen and (max-width: 360px) {
  .AlertsTable__alert-name button {
    display: none;
  }
}