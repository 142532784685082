@import 'src/common/styles/colors';

.NotificationsPage {
  display: flex;
  flex-direction: column;
  align-self: center;
  border-radius: 5px;
  color: white;

  .MessageBox {
    margin: 0px;
    padding: 1.75rem;

    &__text {
      display: flex;
      align-items: center;

      p {
        margin-left: 10px;
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
        color: white;
      }
    }

    &__warning {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 60px;
  }

  &__title {
    font-size: 2rem;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }

  &__add-notification {
    display: flex;
    justify-content: flex-end;
  }
}

.button-cta-3 {
  background-color: $purple;
  color: $lightgray;
  text-align: center;
  opacity: 1;
  outline: none;
  display: inline-flex;
  align-items: center;
  font-weight: normal;
  font-size: 0.8em;
  padding: 5px 10px;
  border-radius: 15px;
  transition: all 0.2s ease-in;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
}
