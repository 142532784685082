@import "src/common/styles/colors";

.Shimmer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: -1px;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: transparentize($neutral-20, 0.4);

  &__gradient {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.05) 30%,
      rgba(#fff, 0.1) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 1.7s infinite ease;
    opacity: 0.6;

    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }
  }
}
